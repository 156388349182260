@import "../../variables";

.tab {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  margin-right: -1px;
  border-color: $darkgrey;
  float: left;

  &.selected {
    border-width: 0 1px 0 1px;
    background: linear-gradient(
      180deg,
      $table-row 14%,
      transparent 77%
    );
  }
}
