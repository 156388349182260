@import '../../variables';

.expanded-view-container {
  background-color: $secondary-bg;
  color: $font-color;
  display: flex;
  flex-direction: row;
  cursor: default;

  .expanded-item {
    padding-left: 80px;
    color: $font-color;
  }

  .expanded-item-title {
    text-align: right;
    padding-right: 10px;
    color: $grey;
    font-size: 16.16px;
    line-height: 25px;
    margin-right: 20px;
    width: 100px;
    white-space: nowrap;
    vertical-align: top;
  }

  .expanded-item-value {
    text-align: left;
    font-size: 16.16px;
    line-height: 22px;
    padding-right: 35px;

    &.Detection {
      &.Details {
        white-space: pre-wrap;
        padding-top: 3px;
      }
    }

    ul {
      padding-left: 0;
      list-style-type: none;
      list-style-position: inside;
      margin-bottom: 10px;
    }

    li {
      margin-bottom: 0;
    }

    pre {
      margin-top: 0;
      margin-bottom: 0;
    }

    .threat-indicators {
      padding-top: 1.5px;

      .category-title {
        font-weight: 900;
      }

      .indicators-list {
        padding-left: 10px;
        list-style-type: disc;

        .tactics-list {
          list-style-type: circle;
          padding-left: 30px;

          a {
            text-decoration: none;
            color: $yellow;
            cursor: pointer;
          }
        }
      }
    }
  }

  .clickable-link:hover {
    color: $blue;
  }

  .expanded-item-title-bold {
    text-align: right;
    padding-right: 10px;
    color: $label;
    font-size: 16.16px;
    line-height: 31px;
    margin-right: 20px;
    width: 100px;
    white-space: nowrap;
    vertical-align: top;
    font-weight: bold;
  }

  .left-container {
    flex: 1;
    padding-left: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 0;

    &.endpoint {
      padding-left: 0;
      height: inherit;
    }
  }

  .right-container-height {
    border-left: 1px solid $border;
    padding-left: 15px;
    flex: 1;
    overflow-y: scroll;

    .right-container {
      height: 0;
      padding: 15px;

      .status-bar {
        border-bottom: 1px solid $border;
        padding: 10px 0 0 40px;
      }

      .severity {
        display: flex;
        margin-top: 20px;
        padding-left: 60px;

        .severity-icon {
          width: 25px;
        }

        .severity-title {
          margin-left: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .right-container-height::-webkit-scrollbar {
    width: 0;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: $grey;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightgrey;
  }
}