@import "../../../variables";

.add-service-modal {
  position: absolute;
  right: 25.2%;
  margin-top: 8px;
  padding: 15px 15px 0 15px;
  background: $secondary-bg;
  font-size: 14px;
  line-height: 0.5;
  z-index: 99;
  border: 1px solid $border;

  .add-service-title {
    padding: 0 0 5px 0;
    font-size: 12.5px;
    color: $font-color;
  }
}
