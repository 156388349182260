@import "../../variables";

$ip-address: 15%;
$status: 10%;
$label: 12%;
$last-seen: 15%;
$total-events: 13%;
$comment: 35%;

.connections-list-modal {
  min-width: 1000px;

  .modal-data {
    min-height: 400px;
  }

  .modal-title {
    &.connections-list {
      padding-left: 50px;
    }
  }

  .page-container {
    height: 44px;
    padding-top: 3px;
    justify-content: space-between;
    align-items: baseline;

    &.onboarding {
      margin-left: 60px;

      .total-results {
        display: flex;
        align-items: baseline;
        margin-right: 20px;
        font-family: $paragraph;
        color: rgba(255, 255, 255, 0.6);

        .total {
          margin-left: -10px;
          font-size: 25px;
          letter-spacing: -0.06px;
          margin-right: 10px;
        }
      }
    }

    .checkbox-icon-container {
      min-width: 30px;
    }

    .radio-container{
      margin-right: 100px;
    }

    .firewall-radios {
      .radio-selection {
        display: flex;
        align-items: baseline;
        width: 280px;

        .tooltip-container {
          margin-left: -8px;

          .tooltip {
            &.bottom {
              width: 200px;
              left: -75px;
            }
          }
        }
      }
    }
  }

  .pagination-controls {
    width: 180px;
    margin-top: 0;
  }

  .reset-filters-button {
    display: none;
  }

  .export-bau {
    display: none;
  }

  .tooltip-container {
    .tooltip {
      width: 500px;

      &.bottom {
        top: 15px;
        left: 200px;
      }
    }
  }

  .modal-tooltip {
    margin: 4px 0 0 5px;
    cursor: pointer;

    .tooltip-container {
      position: absolute;
      top: 9px;

      .tooltip {
        top: -40px;
      }
    }
  }

  .main-list {
    .circle {
      position: relative;
      top: 15px;
      display: flex;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-left: -20px;
      margin-top: 6px;
      background-color: $grey;

      &.green {
        background-color: $green;
      }

      &.red {
        background-color: $red;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.grey {
        background-color: $darkgrey;
      }
    }

    a {
      text-decoration: none;
      color: $grey;
    }

    .sub-list {
      margin-top: 10px;
    }
  }

  .connections-list-table {
    .service-status {
      &.table-row {
        align-items: center;
        overflow: hidden;
        cursor: unset;
        font-size: 14px;

        &:hover {
          background-color: $table-row;
        }

        &:hover {
          background-color: $table-row;
        }

        .list-item {
          padding: 0 20px 0 5px;

          .circle {
            margin-left: 0;
          }

          &.ip-address {
            width: $ip-address;
          }

          &.status {
            width: $status;
            padding-left: 19px;
          }

          &.label {
            width: $label;
          }

          &.last-seen {
            width: $last-seen;
          }

          &.total-events {
            width: $total-events;
          }

          &.comment {
            width: $comment;
            padding-right: 0;
          }
        }
      }
    }

    .table-header-container {
      .td-value {
        margin-right: 0;

        &.ip-address {
          width: $ip-address;
        }

        &.status {
          width: $status;
        }

        &.label {
          width: $label;
        }

        &.last-seen {
          width: $last-seen;
        }

        &.total-events {
          width: $total-events;
        }

        &.comment {
          width: $comment;
        }
      }
    }
  }
  .export-btn{
    position: absolute;
    right: 0;
    top: 65px;
    .loader-btn{
      border: none;
    }
    .relative-loader{
      position: relative;
      left: -25px;
      top: -12px;
    }
  }
}
