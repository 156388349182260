@import '../../../variables';

.vuln-graph-container {
  margin-top: 40px;

  .no-data {
    margin: 35px 0 0 10px;
  }

  .graph-title {
    white-space: nowrap;
    font-weight: 450;
    font-family: $heading;
    margin: 0 -200px -10px 10px;
    letter-spacing: 1.5px;
    position: relative;
    bottom: 5px;

    .graph-subtitle {
      font-family: $paragraph;
      color: $grey;
      margin-left: 2px;
      font-size: 15px;
    }
  }

  .vulns-two-line-chart {
    text{
      fill: #899797;
      outline: none;
      stroke: none;
      font-family: $paragraph;
      font-size: 10px;
    }
  }

  .legend-container {
    display: flex;
    margin: 10px 0 -10px 15px;
    font-family: $paragraph;
  }

  .line-chart-container{
    max-width: 700px;
  }
}
