@import '../../../variables';

.update-action-modal {
  border-bottom: 1px solid #616B6B;
  font-weight: bold;

  &.modal-container {
    height: 260px;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: none;
    width: fit-content;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .modal-data {
      width: 600px;
      height: 260px;
      overflow: hidden;

      .modal-title {
        width: 600px;
        display: block;
        font-family: $heading;
        padding: 15px 20px;

        .action-value {
          font-family: $paragraph;
          margin-left: 10px;
        }
      }

      .modal-values {
        padding: 30px 55px;
        font-family: $paragraph;
        font-size: 15px;
        display: flex;
        flex-direction: column;

        .page-loading {
          position: relative;
          top: -80px;
        }

        .text {
          color: #7D9395;
        }

        .radio-selection {
          .MuiMenuItem-root {
            padding: 0;
            margin-left: 25px;
          }
        }

        .update-button {
          height: 25px;
          margin: 0 0 0 350px;
          width: 70px;
          font-size: 15px;
          padding: 0;
          background-color: transparent;
          align-self: end;
        }
      }
    }
  }
}
