@import "../../variables";

.summary-panel-container {
  flex: 1;
  padding: 10px 20px;
  background-color: $background;
  border-radius: 8px;
  margin: 0 20px;
  height: fit-content;
  padding-bottom: 25px;

  .header {
    float: left;
    font-family: $heading;
    color: $grey;
    font-size: 1.75em;
    margin-bottom: 10px;
    text-transform: uppercase;
    width: 100%;

    &.timeline {
      margin-top: 40px;
    }

    &.affected_assets {
      margin-top: 40px;
    }
  }

  .page-loading {
    &.relative-loader {
      margin-left: 0;

      img {
        margin-top: -10px;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .timestamp {
    margin-right: 30px;
  }
  .relative-loader{
    position: relative;
    left: 45%;
    bottom: 40px;
  }
  ul {
    margin-bottom: 0;
  }
  li:last-child{
    margin-bottom: 0;
  }
  .disclaimer{
    margin: -20px 0px 20px 5px;
    color: $lightgrey;
    font-size: 14px;
  }
  .summary-text{
    white-space: pre-wrap;
  }
}