@import '../../variables';

.button {
  background-color: $secondary-bg;
  height: 30px;
  color: $font-color;
  font-size: 16.5px;
  border: 2px solid $border;
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
  font-family: $paragraph;
  min-width: 100px;
  width: -moz-fit-content;
  width: fit-content;

  &:hover {
    color: $blue;
  }

  &:hover {
    color: $blue;
  }

  &.disabled {
    border: 2px solid $grey;
    color: $grey;
    cursor: unset;

    &:hover {
      color: $grey;
    }
  }
}
