@import "../../../variables";

.virtual-machine-container {
  .copy-container {
    position: absolute;
    font-family: $paragraph;
    right: 150px;
    top: 50px;
    border: 1px solid $border;
    padding: 7px 2px 2px 12px;
    background-color: $secondary-bg;

    span {
      white-space: nowrap;
    }

    .title {
      font-size: 15px;
      color: $grey;
      margin-bottom: 0;
    }

    .copy-icon {
      &.copied {
        color: $green;
      }
    }
  }
}
