@import '../../variables';

.date-picker-input {
  input {
    background-color: $secondary-bg;
    display: flex;
    text-align: center;
    color: $font-color;
    font-size: 14px;
  }

  &.true {
    .MuiInputBase-input {
      border: 1px solid $border !important;
    }
  }

  &.false {
    .MuiInputBase-input {
      border: 0 !important;
    }
  }
}

.date-picker-validation {
  &.true {
    .MuiInputBase-input {
      border: 1px solid $border !important;
    }
  }
}

.date-picker-container {
  width: 46%;

  .MuiSvgIcon-root {
    height: fit-content;
  }

  .MuiInputBase-root {
    background-color: transparent !important;
  }
}

.MuiCalendarPicker-root {
  background-color: $secondary-bg !important;
  color: white !important;
  font-family: $paragraph !important;
}

.MuiDialog-paper {
  background-color: $secondary-bg !important;
  color: $font-color !important;
  font-family: $paragraph !important;
}

.MuiSvgIcon-fontSizeMedium {
  color: $font-color;
  font-family: $paragraph !important;
  font-size: 1rem !important;
}

.MuiTypography-root {
  color: $font-color !important;
  font-family: $paragraph !important;
}

.Mui-disabled {
  color: $grey !important;
}

.MuiPickersCalendarHeader-label {
  font-family: $paragraph !important;
}

.MuiButtonBase-root {
  font-family: $paragraph !important;

  &:hover {
    color: $blue;
  }
}
