@import '../../variables';

.status-bar-container {
  display: flex;
  justify-content: space-between;
  border-top: 5px solid $darkgrey;
  padding-top: 25px;
  margin-top: 30px;
  list-style: none;

  li {
    line-height: 15px;
    z-index: 3;
    font-size: 12px;
    text-align: center;
    
    &.inactive {
      margin-top: -33px;
      width: 12px;
      height: 12px;
      line-height: 12px;
      border-radius: 50%;
      z-index: 3;
      font-size: 12px;
      text-align: center;
      background: $grey;
    }

    &.active {
      background: $title-bg;
      width: 75px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      position: relative;
      top: -47px;
      margin-bottom: -20px;
      padding: 2px;
      color: $font-color;
    }
    &.Ready, &.Applicable{
      width: 100px;
    }
  }
}
