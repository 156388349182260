@import "../../variables";

.traffic-chart-container {
  .legend-container {
    display: flex;
    position: relative;
    left: 2px;
    .legend-text-value{
      font-size: 15px;
    }
  }
  .network-line-chart {
    margin-left: -30px;
    height: fit-content;
    margin-top: -30px;
  }

  .no-data {
    text-align: center;
    padding-top: 2em;
    font-size: 36px;
  }

  .title-and-legend {
    display: flex;
    position: relative;

    .graph-title {
      white-space: nowrap;
      font-weight: 450;
      font-family: $heading;
      letter-spacing: 1.5px;
      position: relative;
      color: $font-color;
      margin-bottom: 5px;
      .graph-subtitle {
        font-family: $paragraph;
        color: $grey;
        margin-left: 2px;
        font-size: 15px;
      }
    }

    svg {
      margin-top: -5px;
    }
  }
  .allow-deny-container {
    float: left;
  }
}
