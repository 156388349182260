@import "../../variables";

.graph-filters-container {
  background-color: $secondary-bg;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-size: 16px;
  color: $font-color;
  padding-left: 20px;
  overflow-x: hidden;
  min-width: 250px;
  max-height: 95vh;
  user-select: none;

  > :nth-last-child(-n+3) {
    margin-bottom: 15%;
  }

  &.true {
    min-width: 50px;
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .expand-btn {
    width: 20px;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    right: 10px;
    margin-bottom: 0;
    user-select: none;

    &.true {
      background-image: linear-gradient($grey, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .custom-checkbox {
    svg {
      color: $font-color;
      border-radius: 0;
    }
  }

  .filters-and-legend {
    .filters {
      margin-right: -190px;

      &.Severity {
        display: flex;
        flex-wrap: wrap;
        width: 260px;
      }
    }

    &.Severity {
      width: 31%;
      box-sizing: border-box;
    }

    .filter-container {
      display: flex;
      align-items: baseline;
      height: 44px;
      margin-left: 12px;
      margin-bottom: -9px;

      &.Severity {
        &.High {
          margin-left: 44px;
        }

        &.Low {
          margin-left: 33px;
        }
      }

      &.Source {
        margin-top: 0;

        .custom-checkbox {
          bottom: 4px;
        }

        &.child-checkbox {
          .custom-checkbox {
            bottom: 8px;
          }

          .filter-label {
            bottom: 11px;
          }
        }

        .filter-label {
          bottom: 4px;
        }
      }

      .legend-color {
        width: 28px;
        height: 28px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: -2px;
        margin-right: 7px;

        &.okta_useridstellar-okta {
          .custom-checkbox {
            bottom: 6px;
          }

          .filter-label {
            bottom: 8px;
          }
        }

        &.highly_connected_ip {
          margin: 0 -5px 0 0;
          width: 33px;
          height: 33px;
        }

        &.vulnerability {
          position: relative;
          width: 30px;
          height: 25px;
          margin: 0 1px 0 -4px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 22px solid $orange;
          }

          .filter-icon {
            position: absolute;
            z-index: 2;
          }
        }

        &.detection {
          border-radius: 0;
          width: 20px;
          height: 20px;
        }

        &.severity {
          border-radius: 0;
          width: 20px;
          height: 20px;
          bottom: -2px;
        }
      }

      .filter-label {
        margin-left: 5px;
        position: relative;
        bottom: 2px;
        text-transform: capitalize;
      }

      &.child-checkbox {
        margin-left: 48px;

        .custom-checkbox {
          margin-left: 15px;
        }
      }
    }
  }

  .preset-dropdown-container {
    float: left;
    margin-top: 30px;
    margin-left: -20px;
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    .preset-container {
      flex: 1;
      display: flex;

      .label-container {
        padding: 12px 30px;
        cursor: pointer;
        border-top: 1px solid #616B6B;
        border-bottom: 1px solid #616B6B;
        flex: 1;
        white-space: nowrap;
        font-family: $paragraph;
        color: $grey;
        height: 50px;
        margin-right: -325px;

        span {
          margin-right: 5px;

          &.label-selected {
            font-size: 12px;
            position: relative;
            top: -26px;
            background-color: $grey;
            padding: 0 5px;
          }
        }

        .current-selection {
          max-width: 250px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          margin-top: -25px;
          padding-left: 5px;
          font-family: $paragraph;
        }
      }

      .preset-modal {
        font-family: $paragraph;
        z-index: 999;
        background-color: $secondary-bg;
        width: auto;
        white-space: nowrap;
        padding: 5px 0;
        margin-right: -32px;
        font-size: 13.5px;
        margin-top: 49px;
        position: absolute;

        .preset-item {
          color: $font-color;
          cursor: pointer;
          padding: 5px 20px;
          margin-bottom: 0;


          &:hover {
            color: $blue;
          }
        }
      }
    }

    .caret {
      color: $font-color;
      height: 13px;
      margin-bottom: 2px;
      margin-left: 2px;
    }
  }

  .filter-header {
    width: 100%;
    margin-left: 10px;
    margin-top: 20px;
    font-family: $heading;
    font-size: 16px;
    color: $grey;
  }

  .llm-summary-container {
    color: $grey;
    margin: -22px 10px;

    .relative-loader {
      position: relative;
      left: -23px;
    }

    .llm-summary-title {
      font-family: $paragraph;
      margin-bottom: 10px;
    }

    .llm-summary-item {
      font-size: 15px;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .llm-summary-item:last-child {
      margin-bottom: 10px;
    }

    .llm-summary-item:hover {
      color: $blue;
      font-family: $heading;
    }

    .modal-container {
      left: 32%;
      width: 500px;
    }

    .modal-data {
      padding: 20px;
      height: fit-content;
      max-height: 500px;
    }

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-content {
      font-family: $paragraph;
    }

    .llm-summary-content {
      height: 80px;
    }

    .llm-error {
      color: $red;
      font-size: 14px;
      margin-bottom: 7px;

      .err-icon {
        position: relative;
        padding-top: 2px;
        top: 2px;
        fill: $red;
      }
    }
  }
}

@media (min-width: 1681px) {
  .graph-filters-container {
    > :nth-last-child(-n+3) {
      margin-bottom: 12%;
      padding-bottom: 17%;
    }
    .llm-summary-container {
      margin-top: 5%;
    }
  }
}
