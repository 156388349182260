@import '../../variables';

.page-filters-container {
  display: flex;
  border-top: 1px solid $border;
  font-family: $paragraph;
  color: $font-color;

  &.true {
    height: 3.7em;
    overflow: hidden;
    cursor: pointer;
  }

  .input-label {
    font-size: 14px;
    margin-top: 1px;
    color: $grey !important;
  }

  .dropdown-checkbox-title {
    color: $font-color;

    &.Cloud {
      display: none;
    }

    &.New_Vulnerabilities {
      cursor: pointer;
    }
  }

  .dropdown-checkbox-container {
    &.Cloud {
      padding-top: 45px;
      color: $font-color;
      margin-left: -40%;
    }

    .filter-tooltip {
      white-space: nowrap;
      width: fit-content;
      margin-top: -30px;
      margin-left: -40%;
    }
  }

  .expand-btn {
    position: relative;
    left: 34px;
    width: 12px;
    top: 3px;
    font-size: 39px;
    color: $font-color;
    cursor: pointer;

    &.true {
      font-size: 21px;
      top: 19px;
      background-image: linear-gradient($font-color, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  fieldset {
    border-color: $border !important;
  }

  .page-filters {
    display: flex;
    justify-content: space-between;
    width: 55%;

    &.medium-width {
      width: 50%;
    }

    &.reduce-width {
      width: 32%;
    }

    &.increase-width {
      width: 60%;
    }
  }

  #form-search-bar {
    margin-right: 10%;
    border-radius: 0;
  }

  #search-field {
    color: $grey
  }

  .MuiInputBase-root {
    background-color: $secondary-bg;
    border-radius: 0 !important;
    color: $font-color !important;
  }

  .MuiFormLabel-root {
    &.Mui-focused {
      color: transparent !important;
    }
  }

  .filter-container {
    display: flex;
    padding-left: 39px;
  }

  .filter-item {
    white-space: nowrap;

    &.true {
      background-image: linear-gradient($font-color, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.LEVEL {
      margin-left: 35px;
    }
  }
}
