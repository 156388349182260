@import '../../variables';

.multi-tenant-panel {
  width: 49vw;
  height: 250px;

  .investigations-panel {
    justify-content: space-between;
  }

  .graph-title {
    font-family: $heading;
    letter-spacing: 2px;

    .sub-title {
      font-family: $paragraph;
      color: $grey;
    }
  }

  .legend-container {
    display: flex;
  }

  .chart-container {
    .trend-graph {
      margin-left: -30px;
    }
  }

  .panel-tables {
    &.investigations {
      margin-top: 65px;
    }

    .title {
      white-space: nowrap;
      font-family: $paragraph;
    }

    .detection-value {
      color: $grey;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      .label {
        text-transform: capitalize;
        cursor: pointer;
      }

      .value {
        font-family: $paragraph;
      }
    }
  }
}
