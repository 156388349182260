@import './styles/variables.scss';

body, html {
  overflow-x: hidden;
  min-height: 100vh;
}

:root {
  --background: #{$background};
  --font-color: #{$font-color};
  --border: #{$border};
  --secondary-bg: #{$secondary-bg};
  --lightgrey: #{$lightgrey};
  --blue: #{$blue};
  --green: #{$green};
  --yellow: #{$yellow};
  --dark-yellow: #{$dark-yellow};
  --orange: #{$orange};
  --red: #{$red};
  --critical: #{$critical};
  --high: #{$high};
  --medium: #{$medium};
  --low: #{$low};
  --low-font: #{$low-font};

  --black: #{$black};
  --white: #{$white};
  --grey: #{$grey};
  --darkgrey: #{$darkgrey};
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  margin: 0 auto;
  background-color: black;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: $paragraph;
  font-size: 16px;
  line-height: 1.47;
  color: $font-color;
  text-align: left;
  background-color: $background;
  overflow-y: scroll;

  #root {
    overflow: hidden;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  #router {
    display: flex;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &.signed::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(to bottom, #002c34, black);
  }

  .all-page-container {
    min-height: 110vh;
  }

  .page-title {
    font-size: 21px;
    color: $white;
    margin-bottom: 0;
    letter-spacing: 2px;
    padding: 20px 50px;
    font-family: $heading;
    font-weight: 500;
    white-space: nowrap;
  }

  .pointer {
    cursor: pointer;
  }

  .flex {
    display: flex;
  }

  .invisible {
    visibility: hidden;
  }

  .disable-click {
    pointer-events: none;
  }

  .disabled-grey {
    filter: grayscale(100%)
  }

  .coming-soon-text {
    white-space: nowrap;
    color: $grey;
    font-family: $heading;
  }

  .low-opacity {
    opacity: 0.2;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .yellow-text {
    color: $yellow;
  }

  .green-text {
    color: $green;
  }

  .circle-graph-title {
    font-family: $heading;
  }

  .clickable-link {
    text-decoration: none;
    color: $font-color;
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  .action-btn {

  }
}

header, main, nav, section {
  display: block;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;

  li {
    margin-bottom: 10px;
  }
}

b,
strong {
  font-weight: 700; // Add the correct font weight in Chrome, Edge, and Safari
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;

  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

textarea {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(192, 208, 208, 0.5);
  background-color: #ffffff;
  height: 130px;
  resize: none;
  color: white;
  font-size: 16px;
  padding: 10px;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
}

img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
  max-width: 100%;
}

@media (max-width: 1680px) {
  body {
    #root {
      width: 1680px;
      overscroll-behavior: auto;
    }
  }
}

.fixed-table-container {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 85vh;
}

.fixed-table-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
} 