@import "../../../variables";

.cloud-onboarding-table {
  border-spacing: 0;
  width: 75%;
  margin-bottom: 50px;

  .account-btn {
    margin: 0 15px 0 0;
  }

  .cloud-table-row {
    height: 50px;
    background-color: $secondary-bg;

    .cloud-table-cell {
      border: 1px solid $border;
      border-bottom: 0;
      color: $font-color;
      padding-left: 20px;
      padding-right: 15px;
    }
  }

  .cloud-table-row:last-child {
    .cloud-table-cell {
      border-bottom: 1px solid $border;
    }
  }

  .no-accounts-text {
    display: none;
  }
}
