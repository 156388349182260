@import '../../../variables';

.assets-and-details-table {
  &.new-vuln {
    &.table-container {
      .export-container {
        justify-content: flex-end;
        margin-right: -65px;
      }

      .td-value {
        width: unset;
        margin-right: 0;

        &.timestamp {
          width: 0;
        }

        &.asset_name {
          margin-right: 0;
          width: 20%;
        }

        &.ip_address {
          width: 20%;
          text-transform: capitalize;
        }

        &.os_platform_name {
          width: 20%;
        }

        &.vulnerability_count {
          width: 20%;
        }

        &.missing_patch_count {
          width: 20%;
        }
      }

      .table-row {
        &:hover {
          background-color: $secondary-bg;
        }

        &.selected {
          background-color: $secondary-bg;
        }
      }
    }
  }
}
