@import '../../variables';

.right-dashboard {
  .graph-container {
    margin-bottom: 60px;
    &.vulnerabilities-panel {
      .title-and-legend {
        &.title {
          margin: 15px 0 10px 0;
        }

        .graph-title {
          margin-bottom: 0;

          .beta-icon {
            width: 45px;
            padding-bottom: 4px;
            margin-left: 10px;
          }
        }
      }

      .legend-container {
        margin-top: -15px;
      }
    }
  }

  .vulnerabilities-tables {
    justify-content: start;
    padding-bottom: 100px;
    .panel-tables {
      margin-top: 20px;

      &:first-child {
        margin-right: 70px;
      }

      .title {
        margin-bottom: 2px;
        letter-spacing: 1.2px;
      }

      .score {
        text-align: right;
        color: #727E7E;
        margin-bottom: 0;
        font-size: 14px;
      }

      .value {
        font-family: $paragraph;
        color: #727E7E;
      }

    }
    a{
      color: inherit;
      text-decoration: inherit;
    }
  }

  .vuln-panel-tabs {
    height: 40px;
    position: relative;
    width: fit-content;

    .tab {
      height: 25px;
      font-size: 14px;
      color: $grey;
      letter-spacing: 2px;
      font-family: $paragraph;
      text-transform: uppercase;

      &.selected {
        color: $lightgrey;
      }
    }
  }
  .bar-chart-container{
    position: relative;
    height: 200px;
    margin-left: -25px;
  }
  .no-data{
    width: 50px;
    position: relative;
    margin: auto;
    margin-top: 50px;
  }
  .bar-chart-tooltip{
    position: absolute;
    right: -30px;
    bottom: 15px;
  }
}
