@import "../../variables";

.expanded-tickets-container {
  background-color: rgba(255, 255, 255, 0.1);
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $darkgrey;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .close-icon {
    position: relative;
    cursor: pointer;
    z-index: 5;
    top: 4px;
    margin-right: 10px;
    float: right;
  }

  .expanded-header {
    display: flex;
    justify-content: space-between;
    min-width: 36vw;
    margin: -10px 0 -20px 0;
    white-space: nowrap;
    letter-spacing: 3px;
    font-family: $heading;
    height: 70px;

    .severity-container {
      display: flex;
      align-items: center;
      justify-content: left;
      position: relative;
      margin: -3px 0 0 5px;
      height: 25px;
      border-radius: 5px;
      border: solid 0.75px transparent;
      border-right-style: hidden;
      letter-spacing: 1px;
      font-family: $paragraph;
    }

    .date-ticket {
      display: flex;
      justify-content: space-between;

      .ticket {
        color: $grey;
        margin-left: 10px;
      }
    }

    .status {
      float: right;
      font-family: $paragraph;
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      margin-top: -2px;
    }

    .assignee {
      position: absolute;
      top: 20px;
      color: $grey;
      font-size: 14px;
      font-family: $heading;
      letter-spacing: 1px;

      .label {
        font-family: $paragraph;
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 14px;
  }


  .tickets-header {
    position: relative;

    .loader-container {
      position: relative;
      right: 20px;
      bottom: 15px;
    }

    .header-content {
      position: absolute;
      max-width: 700px;
      max-height: 240px;
      top: 55px;
      padding-left: 20px;
      padding-right: 15px;
      display: flex;
      overflow-wrap: break-word;

    }
  }

  .description {
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;

    hr {
      margin: 10px -70px;
    }
  }

  .attachments {
    padding-bottom: 5px;
    margin-left: -12px;

    .relative-loader {
      position: relative;
      left: 7px;
    }

    .clickable-link {
      .file-icon-attachment {
        display: flex;

        .file-icon {
          width: fit-content;
          margin-right: -2px;
        }

        .file-name {
          padding-top: 13px;
          color: $grey;
          font-size: 15px;
        }
      }
    }

    a:link {
      text-decoration: none;
    }
  }

  hr {
    border: 0.5px solid $darkgrey;
    margin-left: -20px;
  }

  .row {
    display: flex;
    overflow-x: hidden;
    padding-top: 5px;


    img {
      margin-right: 20px;
      width: 50px;
      margin-left: -60px;
      height: 50px;
    }

    &.description {
      p {
        font-size: 16px;
      }
    }

    &.comment-fields {
      font-size: 15px;
      color: $grey;
      display: inline;

      .comment-info {
        padding-bottom: 10px;
        padding-right: 2px;
        color: $grey;
        display: flex;

        .comment-label-value-author {
          display: flex;
          width: 81%;

          .comment-label-value {
            margin-right: 4px;
          }

          .comment-author {
            width: 60%;
            font-family: $paragraph;
          }
        }

        .comment-time {
          width: 25%;
          text-align: end;
        }
      }
    }

    &.status {
      padding-left: 60px;

      .status-info {
        color: $grey;

        .status-label {
          float: left;
        }

        .status-value {
          float: left;
          color: $lightgrey;
        }

        .status-timestamp {
          text-align: end;
          float: right;
        }
      }
    }
  }

  .comments {
    .user-comment {
      padding-top: 10px;
      font-family: $paragraph !important;

      form {
        display: contents;
      }

      .form-title {
        font-family: $heading;
        color: $grey;
        font-size: 15px;
        margin-bottom: 5px;
      }
    }

    .comment-label {
      padding-right: 10px;
    }

    img {
      position: relative;
      left: 48px;
      margin-right: 70px;
      width: 50px;
      margin-left: -60px;
      height: 28px;
    }

    .form-header {
      font-family: $heading;
      color: $grey;
      margin-bottom: 5px;
    }

    .add-comment-input {
      background-color: var(--ds-background-input, white);
      border: 1px solid var(--ds-border-input, #dfe1e6);
      box-sizing: border-box;
      height: 150px;
      padding-left: 20px;
      color: black;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 100%;
      font-family: $paragraph;
    }

    .comment-submit-button {
      float: right;
    }

    .comment {
      color: $white;
      font-size: 16px;
    }

    .status {
      width: 24vw;
      white-space: break-spaces;
      color: $lightgrey;
    }
  }

  .upload-text {
    float: right;
    position: relative;
    margin-right: 5px;
    top: 2px;
    cursor: pointer;
    color: $grey;
    font-size: 14px;

    &:hover {
      color: $white;
    }
  }

  .comment-divider {
    position: relative;
    left: -20px;
    top: 2px;
  }
}

@media (max-width: 1700px) {
  .expanded-tickets-container {
    .close-icon {
      margin-right: 30px;
    }
  }
}
