@import '../../variables';

.connections-and-controls {
  display: flex;
  justify-content: center;
  height: 271px;

  .tooltip {
    &.connections-panel-tooltip {
      left: 90px;
      top: 15px;
    }
  }

  .loader-container {
    position: absolute;
    z-index: 99;
    left: -2.5vw;
    margin-top: 50px;
  }

  .connections-panel {
    position: relative;
    height: 270px;
    padding-top: 25px;
    right: 4vw;

    .connections-title {
      border: 1.5px solid $border;
      border-radius: 20px;
      letter-spacing: 2px;
      background-color: $title-bg;
      font-family: $heading;
      position: absolute;
      width: 158px;
      display: inline-block;
      left: -65px;
      top: 3px;
      z-index: 1;
      padding: 2px 10px;
    }

    .dashboard-connections-graph {
      background-size: 103.5%;
      background-repeat: no-repeat;
      background-position: center center;
      background-position-y: -9px;
      background-position-x: 0;
      width: 220px;
      display: block;
      margin: auto;
      position: relative;

      .connections-text {
        position: absolute;
        font-weight: 450;
        font-size: 15px;
        letter-spacing: 0.5px;
        transform: scale(1, 1.2);
        font-family: $paragraph;
        white-space: nowrap;

        i {
          font-family: $paragraph;
          font-weight: 350;
          font-size: 15px;
        }
      }
    }

    .connections-svg {
      width: 220px;
      height: 220px;
      cursor: pointer;
    }
  }
}
