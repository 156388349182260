@import "../../variables";

.onboarding-table {
  .clickable {
    display: flex;
    width: max-content;
    cursor: pointer;

    &.active {
      color: $font-color;
    }
  }

  .sort-icon {
    position: relative;
    bottom: 1px;
    left: 4px;
  }

  .checkbox-cell {
    position: relative;
    width: 3%;
  }

  .checkbox-icon {
    position: absolute;
    left: 0;
    top: 7px;
  }

  .checkbox {
    border-radius: 1px;
    margin-right: 4px;
    border: 1px solid;
  }

  .table-icon-container {
    height: 50px;
    display: flex;
    position: relative;
    padding-left: 30px;

    &.contact {
      padding-left: 25px;
    }

    .table-icon {
      width: 40px;
      height: 48px;
      min-width: 28px;
      min-height: 28px;
      object-fit: contain;

      &:hover {
        opacity: 0.8;
        filter: invert(43%) sepia(86%) saturate(1241%) hue-rotate(183deg) brightness(96%) contrast(97%);
      }

      &.resend {
        width: 42px;
        top: 0;
      }

      &.resetMFA {
        width: 42px;
        top: 1px;
        margin-left: -2px;
      }

      &.delete {
        right: 2px;

        &.disabled {
          display: None;
        }
      }

      &.reset {
        width: 39px;
        top: 1px;
        right: 1px;
      }
    }
  }

  .table-column {
    user-select: none;
  }

  &.mobile {
    .table-column {
      &.icon {
        width: 35px;
      }

      &.first_name {
        width: 14%;
      }

      &.last_name {
        width: 17%;
      }

      &.email {
        width: 40%;
      }

      &.agent-installed {
        width: 8%;
      }

      &.buttons {
        width: 14%;
      }
    }

    .edit-input {
      &.first_name {
        width: 100px;
      }

      &.last_name {
        width: 120px;
      }

      &.email {
        width: 210px;
      }
    }
  }

  &.contacts {
    .table-column {
      width: fit-content;
      padding-right: 35px;

      &.icon {
        min-width: 35px;
      }

      &.userRole {
        min-width: 90px;
      }

      &.jobTitle, &.mobilePhone {
        min-width: 130px;
      }

      &.access {
        padding-right: unset;
      }

      &.buttons {
        min-width: 150px;
      }

      &.lastLogin {
        width: 12%;
      }
    }

    .edit-input {
      &.first_name {
        width: 100px;
      }

      &.last_name {
        width: 100px;
      }

      &.email {
        width: 210px;
      }

      &.portalUser {
        width: 90px;
      }

      &.jobTitle {
        width: 90px;
      }

      &.mobilePhone {
        width: 125px;
      }
    }
  }

  &.tenants {
    .table-column {
      padding-right: 30px;
      min-width: fit-content;

      &.onboardingStatus {
        padding-right: 50px;
        min-width: fit-content;
      }

      &.checkbox-placeholder {
        padding-right: 0;
        min-width: 10px;
      }

      &.edit-column {
        padding-right: 0;
        min-width: 40px;
      }
    }
  }

  .confirm-cell {
    height: 5px;
    width: 200px;
    color: $font-color;
    margin-left: auto;
    margin-right: 0;

    &.non-td {
      width: 126px;
    }

    .confirm-dialog {
      position: relative;
      top: 2px;
      float: right;
      display: flex;
      width: 175px;
      align-items: center;

      &.non-td {
        top: 1px;
      }
    }

    .confirm {
      padding-right: 0;
      margin-bottom: 2px;

      :hover {
        color: $blue;
      }
    }

    .cancel {
      padding-left: 5px;
      margin-bottom: 2px;

      :hover {
        color: $blue;
      }
    }

    .relative-loader {
      position: relative;
      left: 12px;
      bottom: 2px;
      height: 47px;
    }
  }

  .custom-checkbox {
    svg {
      color: $font-color;
      border-radius: 0;
    }
  }

  .table-checkmark {
    position: relative;
    left: 47px;
  }

  .status-container {
    width: 27px;

    &.Active {
      margin-left: 3px;
    }
  }

  .table-checkmark-text {
    position: relative;
    left: 57px;
    color: $font-color;
  }

  .resend-tooltip {
    width: 110px;
    white-space: nowrap;

    &.disabled {
      width: 192px;
    }
  }

  .edit-tooltip {
    width: 42px;
  }

  .delete-tooltip {
    width: 60px;
  }

  .resetMFA-tooltip {
    width: 85px;
  }

  .reset-tooltip {
    width: 103px;
    white-space: nowrap;

    &.disabled {
      width: 207px;
    }
  }

  .contacts-tooltip {
    width: fit-content;
    margin-left: -50px;
  }
}
