@import "../../variables";

.dense-table {
  position: relative;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;

  tbody{
    z-index: 1;
  }

  td {
    border-bottom: 1px solid $border;
  }

  .dense-table-header {
    font-family: $paragraph;
    background-color: $table-header-bg;
    color: $font-color;
    height: 30px;
    border-top: 1px solid $border;
  }

  tr {
    width: 100%;
    border-right: 1px solid $border;
    height: 50px;
  }

  .no-data {
    all: unset;
    height: 100px;
    max-width: 100%;
    td {
      position: absolute;
      top: 45px;
      left: 45%;
      border-bottom: none;
    }
  }
}
