@import "../../../styles/variables.scss";

.create-modal-container {
  .modal-container {
    width: fit-content;
    position: fixed;
    left: 49%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-data {
    height: fit-content;
    padding-bottom: 10px;
    padding: 0px;
    overflow: hidden;
    width: 700px;
  }

  .modal-content {
    position: relative;
    padding-left: 20px;
  }

  .modal-title {
    margin-left: -10px;
  }

  .create-content-container {
    .input-container {
      margin-top: 25px;
      margin-bottom: 10px;
      width: 100%;
      list-style-type: none;
      padding-inline-start: 20px;
      font-family: $paragraph;

      li {
        padding-bottom: 10px;
      }

      .input-name {
        padding-top: 2px;
        font-size: 15px;
        font-family: $paragraph;
        color: $grey;
        float: left;
        width: 100px;
      }

      .input-input {
        position: relative;
        top: 2px;
        height: 25px;
        padding-left: 7px;
        font-size: 15px;

        .read-only-input {
          color: $lightgrey;
        }
      }

      textarea {
        padding-top: 2px;
        padding-left: 10px;
      }

      .input-box {
        height: 25px;
        width: 550px;
        font-family: $paragraph;
        font-size: 15px;
        border: none;
        padding-left: 5px;

        &.description {
          height: 100px;
          border-radius: 1px;
          color: $black;
          margin-top: 5px;
        }
      }
    }
  }

  .modal-dropdown-container {
    position: relative;
    float: left;
    cursor: pointer;

    .selection {
      color: $lightgrey;
      user-select: none;

      &.flex {
        align-items: center;
      }
    }

    .dropdown-options {
      position: fixed;
      min-width: 140px;
      border: 1px $grey solid;
      border: 1px $darkgrey solid;
      padding-left: 10px;
      padding-top: 5px;

      background-color: $background;
      font-size: 15px;
      z-index: 1;

      li {
        padding-bottom: 0px;
      }

      .list-item {
        width: fit-content;
        color: $grey;

        padding-right: 10px;
        overflow-x: hidden;
      }

      .list-item:hover {
        color: $white;
      }
    }
  }

  .files-container {
    position: relative;
    margin-top: 100px;

    &.upload {
      .upload-text {
        position: relative;
        right: 90px;
        top: -10px;
      }

      .file-list-container {
        left: 10px;
      }
    }
  }

  .files-btn-container {
    position: relative;
    bottom: 25px;
    left: 100px;

    .upload-text {
      position: relative;
      cursor: pointer;
      color: $grey;
      font-size: 14px;
    }

    .upload-text:hover {
      color: $blue;
    }

    .file-size-error {
      position: absolute;
      top: 9px;
      left: 140px;
      color: $red;
      font-size: 14px;
    }
  }

  .loader-btn-container {
    position: relative;
    top: -10px;
    left: 544px;
  }

  .file-list-container {
    position: relative;
    top: -20px;
    height: auto;
    max-height: 200px;
    overflow: auto;
    width: 75%;
    left: 100px;
    margin-bottom: 5px;

    .file-list {
      list-style: none;
      padding: 0;
      list-style-type: none;
      columns: 2;
      margin-right: 5px;
      -webkit-columns: 2;
      -moz-columns: 2;

      .file-list-item {
        position: relative;
        display: flex;
        border: 3px solid $darkgrey;
        padding-left: 10px;
        padding-right: 5px;
        background-color: $secondary-bg;
        font-size: 14px;
        height: 30px;
        line-height: 25px;
        overflow: hidden;
        color: $lightgrey;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 200px;
          height: 30px;
        }
      }

      .file-list-item::-webkit-scrollbar {
        display: none;
      }

      .file-list-item button {
        display: block;
        margin-left: auto;
        margin-right: 0;
        right: 0px;
        margin-top: 3px;
        border: none;
        background-color: unset;
        cursor: pointer;
        color: white;
      }
    }
  }

  #select-files {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .error-msg {
    height: 300px;

    div {
      color: $red;
      position: relative;
      top: 50px;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      max-width: 500px;
    }
  }

  input:focus {
    outline: none;
  }

  .relative-loader {
    position: relative;
    height: 30px;
    bottom: 5px;
  }

  .critical-warning {
    position: absolute;
    top: 165px;
    left: 200px;
    width: 330px;
    color: $red;
    font-size: 15px;
  }

  .dragdrop-container {
    position: relative;
    bottom: 60px;
    margin-left: 10px;
  }

  .dragdrop-area {
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.2);
    height: 140px;
    width: 637px;
  }

  .dragdrop-content {
    position: relative;
    width: 270px;
    margin: auto;
    top: 38px;

    .dragdrop-text {
      position: relative;
      top: 2px;
      left: 5px;
    }

    .upload-icon {
      height: 60px;
    }
  }

  .input-error {
    color: $red;
    font-size: 14px;
    position: relative;
    float: right;
    top: -7px;
    left: -140px;
  }
}
