@import "../../../variables";

.defender-onboarding-container {
  border-top: solid 1px $border;
  margin: 30px -77px 0 -74px;
  padding: 50px 80px;
  color: $font-color;

  .microsoft-login {
    margin-left: 10px;
  }

  .table-icon {
    &.delete {
      margin-left: 5px;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 25px;
    justify-content: space-between;

    .title {
      display: flex;
    }

    .crowdstrike-title {
      margin: 40px 20px 20px 15px;
    }
  }

  .cloud-onboarding-table {
    margin-top: 40px;
  }

  .cloud-modal {
    .page-loading {
      top: 15px;
      right: 75px;
      position: fixed;
    }
  }

  .display-data {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin-bottom: 30px;
    height: 20px;

    .stat-label {
      float: left;
      color: $grey;
      margin-right: 60px;
    }

    .stat-number {
      color: $font-color;
      margin-left: 15px;
    }
  }

  .clickable-link {
    &:hover {
      .o365-icon {
        opacity: 0.8;
      }
    }
  }

  .grant-access {
    margin-bottom: -25px;
    height: 70px;
  }

  .cloud-table-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;

    .test-error {
      position: initial;
      margin-right: 40px;
    }

    .feedback-icon {
      margin: 10px;
    }
  }

  .account-form-container {
    margin-top: 20px;
  }

  .cs-edit-btn {
    position: relative;
    margin-top: 47px;
    fill: $font-color;
    cursor: pointer;
  }

  .cs-edit-btn:hover {
    fill: $blue;
  }

  .cs-options {
    margin-top: 15px;

    .cs-options-modal-toggle {
      color: $grey;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }

    .label {
      color: $grey;
      margin-right: 5px;
      font-size: 16px;
    }

    .selected-value {
      color: $grey;
      margin-right: 10px;
    }

    .cs-options-icon {
      position: relative;
      top: 2px;
      cursor: pointer;
      fill: $grey;

      &:hover {
        fill: $blue;
      }
    }
  }
}
