@import "../../variables";

.results-panel {
  position: relative;
  background-color: $background;
  color: $black;
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  font-family: $paragraph;
  margin-bottom: 20px;
  overflow: auto;

  .header {
    float: left;
    font-family: $heading;
    color: $grey;
    font-size: 1.75em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .panel-icon {
    font-size: 28px;
    color: $lightgrey;
  }

  .collapse-button {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }

  .text-box {
    background-color: $lightgrey;
    color: $black;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; 
    width: 100%;
    float: left;
  }

  .actions {
    float: right;
    margin: 5px 50px 20px 0px;
    position: relative;
  }

  .action-button {
    display: none; // delete once API is ready
    margin-left: 15px; 
    float: left;
    cursor: pointer;
    .MuiSvgIcon-root {
      color: $lightgrey;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
