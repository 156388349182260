@import "../../../variables";

.add-account-dropdown {
  .dropdown-container {
    user-select: none;
    float: left;
    height: 30px;
    left: 40px;
    margin-right: 10px;
    border: 2px solid $border;
    background-color: $secondary-bg;
    z-index: 10;
    width: 215px;
    cursor: pointer;

    &.selected {
      border-bottom: 1px solid $border;
    }

    .dropdown-label {
      position: relative;
      top: 2.5px;
      left: 9px;
      color: $font-color;
      font-size: 15px;

      &:hover {
        color: $blue;
      }

      .down-arrow {
        position: relative;
        top: 3px;
        left: 1px;
      }
    }

    .dropdown-options {
      list-style-type: none;
      border: 2px solid $border;
      border-top: none;
      min-width: calc(100% + 4px);
      position: relative;
      left: -2px;
      padding: 4px 0 0 0;
      z-index: 10;
      background-color: $secondary-bg;

      li {
        margin: 0;
      }

      .dropdown-option {
        color: $font-color;
        position: relative;
        padding-left: 15px;
        cursor: pointer;
        height: 28px;
        line-height: 28px;

        &:hover {
          color: $blue;
        }
      }

      .dropdown-option:last-child {
        margin-bottom: 1px;
      }
    }

    .dropdown-checkbox {
      position: relative;
      top: 2.5px;
      right: 7px;
      float: left;
      color: $grey;

      svg {
        color: $grey;
      }
    }
  }
}
