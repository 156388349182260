@import '../../variables';

.labeled-expanded-view-container {
  background-color: $secondary-bg;
  color: $font-color;
  display: flex;
  flex-direction: row;
  cursor: default;

  .expanded-item {
    color: $grey;

    &.left {
      height: 0;
      padding-left: 80px;
    }
  }

  .right-expanded-items {
    display: flex;
  }

  .right-table {
    max-width: 53%;
  }

  .expanded-item-value {
    vertical-align: top;
    max-width: calc(6em * 5);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: $font-color;

    pre {
      margin-top: 0;
      margin-bottom: 0;
      font-family: $paragraph;
      white-space: pre-wrap;
    }
  }

  .expanded-item-title {
    text-align: right;
    padding-right: 10px;
    color: $grey;
    font-size: 16.16px;
    margin-right: 20px;
    white-space: nowrap;
    vertical-align: top;
  }

  .left-container {
    flex: 1 1;
    padding-top: 10px;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 40px;

    &.border {
      border-right: 1px solid $border;
    }
  }

  .right-container {
    flex: 1 1;
    overflow-y: scroll;
    max-width: 53%;
    border: none;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 60px;
    padding-right: 100px;
  }
}