@import "../../../variables";

.vulnerability-scan-onboarding {
  .tab-container {
    display: inline-block;
    width: 100%;
  }

  .vulnerability-description {
    margin-bottom: 40px;
    float: left;
  }

  .vuln-scan-tabs {
    display: flex;
    margin-bottom: 50px;
    white-space: nowrap;
    float: left;
    width: fit-content;
  }

  .tab-border {
    margin-top: 40.5px;
    margin-left: 222px;
    width: calc(100% - 222px);
    border-bottom: 1px solid $darkgrey;
  }

  .link {
    color: $white;
    cursor: pointer;

    &.instructions {
      font-size: 14px;
      margin-left: 20px;
    }

    text-decoration: none;
  }

  .scan_name {
    padding-left: 20px;
  }

  .scan-time-tooltip {
    position: relative;
    left: 86px;
    bottom: 24px;
    cursor: pointer;
    z-index: 100;
  }

  .scan-day-tooltip {
    position: relative;
    left: 136px;
    bottom: 25px;
    cursor: pointer;
    z-index: 100;
    width: 90px;
  }

  .instructions-tooltip {
    position: absolute;
    right: 0;
    top: 8px;

    .tooltip-icon:hover {
      opacity: 0.9;
    }
  }

  .add-ip-container {
    position: relative;
    margin-top: 40px;
    margin-bottom: 50px;
    height: 400px;

    &.edit {
      margin-top: 0;
      margin-bottom: 30px;
      height: 200px;
      width: 700px;

      .ip-input {
        height: 150px;
        width: 450px;
      }

      .add-btn-container {
        width: fit-content;
        left: 345px;
        top: 150px;
        margin-top: 50px;

        .loader-btn {
          position: fixed;
          bottom: 30px;
          right: 40px;
        }
      }
    }

    .add-btn-container {
      position: relative;
      left: 445px;
      margin-top: 15px;
    }
  }

  .dropdown-section {
    position: relative;

    .input-label {
      color: $grey;
    }

    .hostname-dropdown-container {
      position: relative;
      left: 270px;
      bottom: 25px;

      .dropdown-container {
        position: absolute;
        overflow-y: auto;
      }
    }
  }

  .reveal-add {
    width: fit-content;
    color: $font-color;
    cursor: pointer;
    margin-top: -50px;
    margin-bottom: 50px;
    user-select: none;

    &:hover {
      color: $blue;
    }
  }

  .vm-link {
    margin-left: 20px;
    color: $font-color;
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  .vuln-scan-tooltip {
    width: 190px;
    z-index: 100;
  }

  .dropdown-container {
    display: block;

    &.open {
      max-height: 255px;
      overflow-y: scroll;
      z-index: 100;
    }
  }

  .dropdown-title {
    color: $font-color;
    font-weight: 600;
    user-select: none;
  }

  .dropdown-label {
    color: $font-color;
    position: absolute;
  }

  .list-item {
    position: relative;
    z-index: 99;
    background-color: $secondary-bg;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;

    &:hover {
      color: $blue;
    }
  }

  .info {
    font-size: 17px;
    height: 80px;
    float: left;

    .ip-info {
      padding: 25px 100px 15px 20px;
      margin: 0 0 0 20px;
      cursor: pointer;
      font-size: 16px;
      color: $font-color;
      user-select: none;
      position: relative;
      bottom: 25px;
      white-space: nowrap;

      &:hover {
        color: $blue;
      }
    }

    .ip-info-container {
      padding: 7px 35px 2px 12px;
      margin: 0 0 0 -100px;
      position: absolute;
      font-family: $paragraph;
      border: 1px solid $border;
      background-color: $secondary-bg;
      right: -20px;
      top: 35px;

      span {
        white-space: nowrap;
      }

      .title {
        font-size: 15px;
        color: $grey;
      }
    }
  }

  .ip-entry {
    margin: 5px 0;
    position: relative;
  }

  .copy-icon {
    &.copied {
      color: $green;
    }
  }

  .copy-btn {
    position: absolute;
    top: -4px;
  }

  .ip-entry {
    margin-left: 2px;
  }
}
