@import '../../variables';

.maturity-assessment {
  &.top-container {
    display: flex;
    height: 23em;
    flex-direction: column;
    color: $font-color;

    &.true {
      height: 5.4em;
      overflow: hidden;
      padding-bottom: 3em;
    }

    .progress-row {
      display: flex;
      align-items: center;
      color: $grey;
      margin: 0 0 -20px;

      .sub-title {
        letter-spacing: 1px;
        font-family: $heading;

        &.true {
          background-image: linear-gradient($grey, transparent);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .circle-container {
        background-size: 78%;
        background-repeat: no-repeat;
        background-position: center center;
        background-position-y: 12px;
        background-position-x: 12px;
      }
    }

    .expand-btn {
      padding-left: 25px;
      font-size: 39px;
      position: relative;
      bottom: 8px;
      color: $font-color;
      cursor: pointer;
      margin-right: 15px;

      &.true {
        font-size: 21px;
        bottom: 5px;
        background-image: linear-gradient($grey, transparent);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .filters-container {
    display: flex;
    padding: 10px 70px 10px 40px;

    .framework-filters {
      min-width: 276px;

      .loader-container {
        height: 106px;
      }
    }

    .framework-container {
      display: flex;

      .dropdown-checkbox-container {
        color: $font-color;

        .dropdown-checkbox-title {
          color: $font-color;
        }
      }

      .completed-container {
        margin: 30px 50px 0 15px;
        font-family: $paragraph;
        font-weight: 300;

        .completed {
          margin: 0;
          height: 22.5px;
        }
      }
    }

    .page-filters-container {
      border: none;
      display: flex;
      flex: 1;

      .page-filters {
        flex: 1;
      }
    }
  }
}
