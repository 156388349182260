@import "../../variables";

.adhoc-container {
  display: block;
  background: linear-gradient(to bottom, #0f3137, 60%, black);
  height: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 50px;

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    height: 72px;
  }

  .page-title {
    position: relative;
    left: 25px;
  }

  .search-container {
    position: relative;
    width: 100%;
    height: fit-content;
    .input-container {
      position: relative;
      margin-right: 60px;
      float: left;
      margin-top: 20px;
      &.disabled{
        opacity: 0.5;
      }
      &.tenant {
        margin-left: 75px;
      }
      &.search-box {
        margin-top: 47px;
        .input-box {
          height: 35px;
          width: 230px;
          font-size: 15px;
          padding-left: 5px;
        }
      }
      &.investigation-tools {
        height: fit-content;
        .css-14wyt6d-Control, .css-jtwdwp {
          height: unset;
          width: 400px;
        }
        .css-1u9bmax-menu {
          width: unset;
        }
        .css-tj5bde-Svg{
            fill: $darkgrey;
        }
      }
    }
    .submit-button-container {
      margin-top: 49px;
    }
  }

  .selection {
    width: fit-content;
  }

  .results-container {
    position: relative;
    top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    clear: both;
    padding: 40px 50px 0px 50px;
    .module-results{
      width: 50%;
      float: left;
    }
    .summary-results{
      width: 50%;
      float: left;
      &.detection{
        width: 100%;
      }
    }
    .timeline-results{
      width: 50%;
      float: left;
      margin-top: 20px;
    }
  }

  .error-message {
    position: absolute;
    color: $red;
    width: fit-content;
    top: 80px;
    left: 1400px;
    .err-icon {
      position: relative;
      top: 1.5px;
      color: $red;
    }
  }

  .modal-container {
    width: 800px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .modal-content {
      width: 100%;
      overflow: hidden;
    }
    .modal-data {
      width: 100%;
      height: 360px;
    }
    .modal-header {
      padding-left: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid $darkgrey;
      width: 100%;
      font-weight: 600;
      position: relative;
      margin-top: 10px;
    }
    .text-box {
      background-color: $lightgrey;
      color: $black;
      padding: 15px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px; // Margin between content and actions
      width: 750px;
      margin-left: 20px;
      margin-top: 20px;
    }
    textarea {
      color: $black;
      font-family: $paragraph;
      width: 750px;
      margin-left: 20px;
    }
    .loader-btn-container {
      float: right;
      margin-right: 22px;
      margin-top: 10px;
    }
  }
  .adhoc-timeline-section {
    position: relative;
    top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    clear: both;
    padding: 40px 50px 0px 50px;

    margin-left: 10px;
    background-color: #094650;
    border-radius: 8px;
    position: relative;
    width: 50%;
    float: left;
  }
}
