@import "../../../styles/variables.scss";

.upload-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 925px;
  min-height: 575px;
  background-color: $secondary-bg;
  border: 2px solid $border;
  padding: 0;
  font-size: 13px;

  .close-button-container {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 2px;
    top: 2px;
  }

  .dismiss-msg,
  .close-icon {
    color: $font-color;
  }

  .x-button {
    right: 9px;
    bottom: 9px;
  }

  .divider {
    background-color: $border;
  }

  .body-text {
    max-height: 250px;
    width: 300px;
    overflow: auto;
  }

  .body-text::-webkit-scrollbar {
    display: none;
  }

  .modal-container {
    font-family: $heading;
  }

  .title-container {
    border-bottom: 1px solid $border;
    width: 100%;
    height: 60px;
    margin-bottom: 65px;
  }

  .title {
    position: relative;
    left: 25px;
    line-height: 60px;
    font-family: $paragraph;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $font-color;
  }

  .upload-content-container {
    width: 72%;
    margin-left: auto;
    margin-right: auto;
    color: $font-color;
    font-size: 16px;

    .incompatible-msg {
      position: absolute;
      color: $orange;
      font-size: 14px;
      top: 75px;
    }

    .dragdrop-area {
      border: 2px solid $border;
      height: 140px;
    }

    .dragdrop-content {
      position: relative;
      width: 280px;
      margin: auto;
      top: 38px;
      display: flex;
      align-items: center;

      .dragdrop-text {
        position: relative;
        top: 2px;
        left: 5px;
        white-space: nowrap;
      }

      .upload-icon {
        height: 60px;
      }
    }

    .files-btn-container {
      position: relative;
      top: 10px;

      .upload-text {
        position: relative;
        margin-right: 5px;
        top: 2px;
      }
    }

    .upload-btn {
      cursor: pointer;
      color: $font-color;
      border: 2px solid $border;
      text-align: center;
      height: 30px;
      font-size: 15px;
      line-height: 25px;
      padding-left: 25px;
      padding-right: 25px;

      &:hover {
        color: $blue;
      }
    }

    .upload-btn-container {
      position: relative;
      left: 85%;
    }

    .file-list-container {
      position: relative;
      top: 17px;
      height: auto;
      max-height: 90px;
      margin-bottom: 30px;
      overflow: scroll;
      width: 101%;

      .file-list {
        list-style: none;
        padding: 0;
        columns: 2;
        margin-right: 5px;
        -webkit-columns: 2;
        -moz-columns: 2;

        .file-list-item {
          position: relative;
          display: flex;
          border: 2px solid $border;
          padding-left: 10px;
          padding-right: 5px;
          background-color: $secondary-bg;
          font-size: 14px;
          height: 30px;
          line-height: 25px;
          overflow: auto;
          color: $font-color;
        }

        .file-list-item::-webkit-scrollbar {
          display: none;
        }

        .file-list-item button {
          display: block;
          margin-left: auto;
          margin-right: 0;
          right: 0;
          border: none;
          background-color: unset;
          cursor: pointer;
          color: $font-color;
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .upload-content-container::-webkit-scrollbar {
    display: none;
  }

  #select-files {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .tags-container {
    position: relative;

    .tags-text {
      font-family: $paragraph;
      margin-right: 20px;
      color: $font-color;
      font-size: 16px;
    }

    .tags-input {
      position: relative;
      left: 72px;
      bottom: 27px;
      display: flex;
      overflow: scroll;
      width: 592px;
      max-width: 592px;
      border: 1px solid $border;
      border-radius: 1px;
      color: $font-color;
      height: 30px;
      background-color: $white;
    }

    .tags-input::-webkit-scrollbar {
      display: none;
    }

    .tags-input input {
      font-family: $paragraph;
      letter-spacing: 0.5px;
      width: 589px;
      border: none;
      border-radius: 1px;
      padding: 5px;
    }

    input:focus {
      outline: none;
    }

    .tag {
      display: flex;
      align-items: center;
      margin: 3px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 10px 10px;
      padding-right: 5px;
      border: 1px solid $border;
      border-radius: 5px;
      background-color: $secondary-bg;
      white-space: nowrap;
      color: $font-color;
    }

    .tag button {
      position: relative;
      bottom: 1px;
      display: flex;
      padding: 6px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: $font-color;
    }
  }

  .suggestions-container {
    position: relative;
    left: 72px;
    bottom: 20px;

    .label {
      font-family: $paragraph;
      color: $font-color;
      font-size: 13px;
      margin-right: 10px;
    }

    .text {
      font-family: $paragraph;
      color: $font-color;
    }

    .suggestion-button {
      cursor: pointer;
    }
  }

  .container {
    position: relative;
    left: 72px;
    bottom: 27px;
    display: flex;
    overflow: scroll;

    width: 592px;
    max-width: 592px;
    border: 1px solid $border;
    border-radius: 1px;
    color: $font-color;
    height: 30px;
    background-color: $secondary-bg;
  }

  .file-list::-webkit-scrollbar {
    display: none;
  }

  .error-msg {
    position: relative;
    top: 135px;
    left: 360px;
  }
}
