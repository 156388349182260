.firewall-row {
  .checkbox-cell {
    width: 40px;
  }

  .ip {
    width: 350px;
  }

  .table-icon {
    &:hover {
      opacity: 0.8;
      filter: invert(43%) sepia(86%) saturate(1241%) hue-rotate(183deg) brightness(96%) contrast(97%);
    }

    &.delete {
      margin-left: 5px;
    }
  }

  .table-icon-container {
    width: 100px;
  }

  .tooltip {
    width: unset;
  }
}
