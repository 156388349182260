@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(../fonts/OpenSans-VariableFont.ttf) format('truetype');
}

/* font-family*/
$heading: OpenSans, "Open Sans";
$paragraph: OpenSans, "Open Sans";

/* dark theme */
/* background colors */
$background: #1A1A1AFF;
$secondary-bg: #333333FF;

/* Primary Colors */
$black: #000000;
$font-color: #E6E6E6FF;
$border: #808080FF;
$white: #FFF;
$lightgrey: #E6E6E6;
$grey: #808080;
$darkgrey: #333333;
$title-bg: #333333FF;
$table-header-bg: #4D4D4DFF;
$table-hover: #333333;
$table-row: #a4a3a31a;
$label: #333333;

/* Secondary Colors */
$blue: #2795E3;
$green: #4AAB53FF;
$yellow: #F0C037FF;
$dark-yellow: #D2A517FF;
$orange: #F78808FF;
$red: #FF6666FF;
$critical: #CC0000FF;
$high: #F78808FF;
$medium: #F0C037FF;
$low-font: #808080FF;
$low: #333333;
$box-shadow: rgba(0,0,0,.5);
$button-gradient: linear-gradient($secondary-bg, 20%, $background);

/* light theme */
//$background: #FFF;
//$secondary-bg: #E6E6E6FF;
//
///* Primary Colors */
//$black: #000000;
//$font-color: #262626;
//$border: #808080;
//$white: #FFF;
//$lightgrey: #E6E6E6;
//$grey: #999999FF;
//$darkgrey: #333333;
//$title-bg: #FFF;
//$table-header-bg: #CCCCCCFF;
//$label: #333333;
//
///* Secondary Colors */
//$blue: #2795E3;
//$green: #4aab53;
//$yellow: #E3E327;
//$dark-yellow: #ffce44;
//$orange: #f8992b;
//$red: #E81410;
//$critical: #CC0000FF;
//$high: #F78808FF;
//$medium: #F0C037FF;
//$low-font: #808080FF;
//$low: #333333;
//$box-shadow: rgba(0,0,0,.5);
