@import '../../variables';

.bulk-comment-container {
  &.new {
    width: 700px;
    height: 275px;
    left: 31%;
    top: 35%;
    box-shadow: 0 0 0 60vmax rgba(0, 0, 0, 0.5);

    .modal-data {
      height: 100%;
      padding: 20px;
      overflow: hidden;

      .relative-loader {
        position: absolute;
        top: 100px;
      }

      .input-label {
        font-family: $heading;
        font-weight: 500;
        border-bottom: 1px solid #586565;
        margin: -10px -20px 20px;
        padding: 0 20px 10px;
      }

      .input-box {
        color: black;
        font-family: $paragraph;

        &::placeholder {
          color: $darkgrey;
        }
      }

      .comment-text {
        color: #808a8a;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-top: 10px;
        padding-right: 1px;
        white-space: nowrap;

        .add-btn {
          cursor: pointer;
          color: $grey;
          border: 2px solid $grey;
          text-align: center;
          height: 29px;
          font-size: 16px;
          line-height: 25px;
          margin: 0 0 7px 15px;
          padding: 0 10px;

          &.back {
            margin-right: -10px;
          }
        }
      }
    }
  }
}
