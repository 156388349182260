@import "../../variables";

.network-page-container {
  background: $button-gradient;
  min-height: calc(100vh - 55px);

  .header-row {
    border-bottom: 1px solid $border;

    .page-title {
      width: 200px;
    }

    .page-tooltip {
      position: absolute;
      left: 165px;
      top: 76px;
      cursor: pointer;

      .info-icon {
        fill: $grey;
      }
    }
  }

  .maps-container {
    float: left;
    padding-top: 20px;
    width: 60vw;
    min-height: calc(100vh - 55px);
    border-right: 1px solid $border;

    .relative-loader {
      position: relative;
      top: 300px;
    }

    .left-loader {
      min-height: 100vh;
    }
  }

  .network-metrics-right {
    float: left;
    padding: 30px 0 0 4%;
    height: 100%;

    .relative-loader {
      min-height: 90px;
      position: relative;
    }

    .network-metrics-container {
      min-width: 400px;

      .relative-loader {
        min-height: 200px;
      }
    }

    .network-trends-container {
      margin-top: 6vh;

      .relative-loader {
        top: 200px;
      }
    }
  }

  .btn-row {
    position: absolute;
    top: 85px;
    right: 40px;
    display: flex;

    .caret-container {
      &.date {
        .caret {
          margin: 0 0 0 5px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .network-dropdown-container {
    float: left;

    &.action {
      width: 110px;
    }

    &.source {
      width: 100px;
      margin-right: 15px;
    }

    &.dates {
      width: 140px;
      cursor: pointer;
      white-space: nowrap;
      padding-top: 1px;

      .time-dropdown-container {
        position: absolute;
        background-color: $secondary-bg;
        right: -42px;
        top: 35px;
        z-index: 9;
        border: 1px solid $border;

        .time-module-container {
          padding-top: 10px;
        }
      }
    }
  }

  .network-dropdown {
    white-space: nowrap;
    float: left;
    user-select: none;

    .title-container {
      color: $font-color;
    }

    .list-item {
      background-color: $secondary-bg;
      padding: 2px 10px 2px 10px;
      margin-left: -3px;

      &:hover {
        color: $white;
      }

      &.selected {
        color: $white;
      }
    }
  }

  .menu-btn {
    cursor: pointer;
    float: left;
    margin-right: 50px;
    color: $font-color;
    margin-top: 1px;

    &:hover {
      color: $white;
    }
  }

  .legend {
    text-align: right;
    font-size: 14px;
    float: right;
    margin-right: 50px;
    color: $grey;
    margin-top: 25px;
  }
}
