@import "../../../variables";

.account-form-container {
  table {
    border-spacing: 0;
  }

  tr {
    height: 40px;
  }

  td {
    width: fit-content;
    white-space: nowrap;

    &.response-td-data {
      vertical-align: top;
      margin-top: 10px;
      padding-top: 4px;
    }

    &.edit-response {
      vertical-align: top;
      padding-top: 9px;

      .doc-link {
        margin-left: 5px;
        color: $grey;
      }

      .response-modal-note {
        font-size: 14px;
        color: $font-color;
        font-family: $paragraph;

        .bold-note {
          display: inline;
          font-weight: bolder;
          color: $font-color;
        }
      }

      .response-modal-note-italics {
        font-size: 14px;
        color: $font-color;
        font-family: $paragraph;

        .bold-note {
          display: inline;
          font-weight: bolder;
          color: $font-color;
        }
      }
    }
  }

  .fileName-upload {
    display: flex;

    .fileName {
      width: 200px;
      padding-right: 20px;
    }
  }

  min-width: 650px;
  width: 60%;

  .input-container {
    height: 40px;
    margin-bottom: 10px;
    min-width: 650px;
    width: fit-content;
  }

  .input-label {
    line-height: 40px;
    margin-right: 12px;
  }

  .input-box {
    height: 30px;
    width: 500px;
    border-radius: 3px;
    border: 1px solid $border;
    padding-left: 7px;
    font-size: 15px;
    color: $font-color;
    background-color: $secondary-bg;

    &:focus {
      outline: none;
    }
  }

  .input-box::placeholder {
    color: $red;
  }

  .form-btn {
    position: relative;
    height: 22px;
    bottom: 3px;

    &.submit {
      padding-bottom: 40px;

      &.GoogleWorkspace {
        padding-bottom: 0;
      }

      &.SalesForce {
        padding: 0;
      }

      &.MicrosoftDefender {
        margin-bottom: 15px;
      }

      &.AWSCloudTrail {
        margin-bottom: -18px;
      }

      &.OKTA {
        margin-bottom: -18px;
      }
    }

    .border-btn {
      background-color: $secondary-bg;
      height: 28px;
      color: $font-color;
      font-size: 16.5px;
      border: 2px solid $border;
      cursor: pointer;
      margin: 0;
      padding: 0 20px;
      min-width: 100px;
      width: fit-content;

      &:hover {
        color: $blue;
      }
    }

    &.true {
      padding-top: 200px;
      margin-bottom: 30px;
    }

    &.add {
      margin-right: -10px;

      &.MicrosoftDefender {
        margin-bottom: 20px;
      }
    }

    .edit-loader {
      min-width: 120px;
    }

    &.old-client-id {
      padding-top: 40px;
    }
  }

  .input-tooltip-container {
    position: relative;
    bottom: 2px;
    margin-right: 30px;

    .input-tooltip {
      overflow-x: auto;
      white-space: initial;
    }
  }

  .loader-container {
    width: 150px;
  }

  .relative-loader {
    position: relative;
    height: 40px;
    left: 35px;
    bottom: 5px;
  }

  .response-description {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: 50px;
  }
}
