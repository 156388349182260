@import "../../../variables";

.endpoints-onboarding-container {
  .subheader-text {
    color: $font-color;
    margin-bottom: 30px;
    font-size: 17px;
  }

  .instructions-tooltip {
    .instructions-tooltip {
      width: 462px;
    }

    .tooltip-icon:hover {
      opacity: 0.9;
    }
  }

  .tooltip-icon-small {
    position: relative;
    bottom: 1px;
    width: 13px;
  }

  .relative-loader {
    position: relative;
    float: left;
    left: 75px;
  }

  .text-link {
    color: $font-color;
    text-decoration: none;
  }

  .text-link:hover {
    color: $blue;
  }

  .info {
    font-size: 17px;
    height: 80px;

    .cs-info {
      float: left;
      padding-top: 25px;
      padding-bottom: 15px;
      cursor: pointer;
      margin-left: 20px;
      font-size: 16px;
      color: $font-color;
      user-select: none;
      padding-right: 100px;
      position: relative;
      bottom: 25px;
      white-space: nowrap;

      &:hover {
        color: $blue;
      }
    }

    .cid-container {
      position: relative;
      font-family: $paragraph;
      left: 25px;
      bottom: 20px;
      border: 1px solid $border;
      padding: 7px 35px 2px 12px;
      background-color: $secondary-bg;
      float: left;
      margin-left: -100px;

      span {
        white-space: nowrap;
      }

      .title {
        font-size: 15px;
        color: $font-color;
      }
    }

    .copy-btn {
      float: right;
      position: absolute;
      bottom: 4px;
    }

    .copy-icon {
      &.copied {
        color: $green;
      }
    }
  }

  .download-versions-modal {
    position: absolute;
    border: 1px solid $border;
    background-color: $secondary-bg;
    overflow: hidden;
    width: 300px;
    left: 500px;
    top: 300px;

    &.expanded {
      width: fit-content;

      ul {
        columns: 4;
      }
    }

    .modal-header {
      position: relative;
      font-family: $paragraph;
      color: $font-color;
      font-size: 17px;
      padding-bottom: 6px;
      user-select: none;
    }

    .download-options {
      position: relative;
      padding: 10px 0 0 15px;
      min-height: 130px;
    }

    ul {
      columns: 1;
      list-style-type: none;
      padding-left: 1px;
    }

    li {
      margin-bottom: 0;
    }

    .download-option {
      width: fit-content;
      font-family: $paragraph;
      font-size: 17px;
      position: relative;
      user-select: none;
      color: $font-color;
      white-space: nowrap;
      padding-right: 50px;
      max-width: 250px;
      overflow: hidden;

      :hover {
        opacity: 0.9;
        color: white;
      }

      .os-name {
        float: left;
      }

      .os-version {
        float: left;
      }

      .download-icon-small {
        width: 25px;
        margin-left: 5px;
        position: relative;
        bottom: 1px;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .more-btn {
      cursor: pointer;
      position: relative;
      float: right;
      right: 10px;
      margin-top: -40px;
    }

    .more-btn:hover {
      color: $blue;
    }
  }

  .endpoints-total {
    .total-number {
      color: $font-color;
      font-size: 32px;
      margin-right: 14px;
    }

    .total-label {
      color: $grey;
      font-size: 17px;
      margin-right: 24px;
    }

    .total-link {
      color: $grey;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }
}