@import '../../variables';

.expanded-maturity-assessment-container {
  background-color: $secondary-bg;
  width: 47%;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  border: 1px solid $border;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 45vw;
  color: $font-color;

  .expanded-header {
    display: flex;
    justify-content: space-between;
    margin: 10px -30px;
    padding: 0 0 0 20px;
    border-bottom: 1px solid $border;
    white-space: nowrap;
    letter-spacing: 3px;
    font-family: $heading;

    .date-ticket {
      display: flex;
      justify-content: space-between;

      .date {
        margin-right: 5px;
      }

      .ticket {
        color: $font-color;
      }
    }

    .status {
      font-weight: 500;
      margin-right: 25px;
    }

    .close-icon {
      margin: 0 15px 15px -25px;
    }
  }

  .description {
    padding: 0 20px 10px 20px;
    margin: 0 -30px 30px -30px;
    color: $font-color;
  }

  .control-data {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 20px 10px 20px;
    margin: 0 -30px;
    color: $font-color;
  }

  .border-bottom {
    border-bottom: 1px solid $border;
  }

  .expanded-label {
    color: $grey;
    margin: 0;

    &.description {
      margin: 10px 0 0 -30px;
    }
  }

  .comment-container {
    display: flex;
    font-family: $paragraph;
    border-bottom: 1px solid $border;
    margin: 0 -30px;
    padding: 15px 20px 0 20px;
    font-size: 14px;
    justify-content: space-between;

    .author-info {
      .author {
        margin: -10px 0 20px -6px;
        font-weight: 500;
        letter-spacing: -0.3px;
        transform: scale(0.9, 1);
        font-family: $paragraph;
        width: 12vw;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }

    .comment-info {
      width: 60%;

      .status {
        color: $font-color;
      }

      .greyed {
        color: $grey;
      }
    }
  }

  .notes-container {
    margin-left: -10px;
    font-family: $paragraph;
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: -10px;

    .dropdown-title {
      justify-content: flex-end;
    }

    .notes-title {
      font-size: 14px;
      font-weight: 500;
    }

    .dropdown-container {
      &.open {
        position: relative;
        z-index: 9;
        right: -30px;
        top: -1px;
        background-color: $secondary-bg;
        border: 1px solid $border;
        border-radius: 2px;
        padding: 0 0 10px 10px;
        margin-bottom: -158px;
        width: 150px;
        height: 160px;
      }
    }

    .dropdown-title {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .add-comment-input {
    color: $font-color;
    font-family: $paragraph;
    background-color: $grey;
    border: 1px solid $border;
  }

  .audit-header {
    padding: 10px 30px;
    border-bottom: 1px solid $border;
    font-weight: 500;
  }

  .audit-text {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 5px 30px;
  }

  .modal-container {
    top: 10%;

    .modal-data {
      height: 80vh;
    }
  }

  .expand-footer-btns {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 100px;

    .audit-btn {
      display: flex;
      align-items: center;

      .arrow {
        height: 19px;
        width: 20px;
        padding-bottom: 1px;
        color: $font-color;
      }
    }

    .update-button {
      &.button {
        width: 135px;
        background-color: transparent;
        font-weight: 500;
        border: 3px solid $border;
        margin-bottom: 10px;
      }
    }
  }
}

.expanded-maturity-assessment-container::-webkit-scrollbar {
  display: none;
}
