@import "../../variables";

.detection-panel-container {
  padding: 10px 20px;
 
  margin: 0 20px;
  height: 100%;

  .section{
    background-color: #094650;
    border-radius: 8px;
  }

  .header {
    font-family: $heading;
    color: $grey;
    font-size: 1.75em;
    margin-bottom: 10px;
    padding-top: 15px;
    text-transform: uppercase;
    width: 100%;


    &.affected_assets {
      margin-top: 40px;
    }
  }

  .page-loading {
    &.relative-loader {
      margin-left: 0;

      img {
        margin-top: -10px;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  ul {
    padding-left: 15px;
    list-style-position: outside;
  }

  .timestamp {
    margin-right: 30px;
  }

  &.detection {
    display: flex;
    white-space: pre-wrap;
    height: fit-content;
    padding: 0px 0px 0px 0px;

    .detection-content {
      display: flex;
      width: 100%;
    }

    .summary-section {
      width: 45%;
      float: left;
      margin-right: 20px;
      padding: 0px 30px 20px 35px;

      ul {
      list-style: none;
      padding-left: 0;
      }
    }

    .timeline-section {
      padding: 0px 50px 10px 35px;
    }

    .findings-section {
      padding: 0px 50px 10px 35px;
      margin-top: 25px;
    }

    .detection-right-side {
      width: 55%;
      float: left;
    }
  }
}