@import "../../../variables";

.tenant-onboarding-container {
  display: block;
  background: $button-gradient;
  height: 100%;
  min-height: 100vh;
  position: relative;

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    height: 72px;
    .page-title {
      position: relative;
      margin-bottom: revert;
      left: 25px;
    }
  }

  .onboarding-title {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: $heading;
    letter-spacing: 2px;
    font-size: 20px;
    color: #d8d8d8;
  }

  .dropdown-container {
    position: absolute;
    left: 75px;
    padding-top: 35px;
    font-family: $paragraph;
    .tenant-name-container {
      display: flex;
      padding-bottom: 30px;
      .tenant-name-title {
        align-items: center;
        display: flex;
      }
      .tenant-name-value {
        flex: auto;
        padding-left: 55px;
        color: #7d9395;
      }
    }
    .salesforce-container {
      display: flex;
      padding-bottom: 25px;
      .salesforce-title {
        align-items: center;
        display: flex;
      }
      .tenant-account-selection {
        flex: auto;
        padding-left: 18px;
        width: 300px;
        .react-select__control {
          width: 299px;
        }
      }
    }
    .message-container {
      &.error {
        color: $red;
        top: 5em;
      }
      &.success {
        color: $green
      }
      &.shown {
        opacity: 1;
        transition: all 250ms linear;
      }
      &.hidden {
        opacity: 0;
        transition: all 250ms linear 2s;
      }
      position: relative;
      top: 2em;
      left: 19.2em;
      width: 350px;
      .message-icon {
        position: relative;
        top: 3.3px;
        &.error {
          color: $red
        }
        &.success {
          color: $green
        }
      }
      .message-text {
        font-size: smaller;
      }
    }
    .submit-loader {
      position: relative;
      top: 2em;
      left: 21.5em;
      padding: 10px;
      width: 75px;
    }
    .create-tenant-button {
      background-color: transparent;
      border: 2px solid $grey;
      color: $grey;
      cursor: pointer;
      font-family: $paragraph;
      font-size: 16.5px;
      margin-top: 45px;
      margin-left: 311px;
      padding: 3px 35px;
    }
  }
}