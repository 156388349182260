@import "../../styles/variables.scss";

.announcement-modal-container {
  color: $lightgrey;

  .modal-container {
    width: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-data {
      height: fit-content;
      max-height: 450px;
      padding: 50px 30px 40px 30px;
      overflow: hidden;
    }
  }

  .modal-content {
    height: fit-content;
  }

  .modal-top {
    position: relative;

    .title-text {
      position: relative;
      font-family: $heading;
      max-width: 70%;
      margin-left: 25px;
      left: 29px;
      bottom: 0;
      margin-bottom: 10px;
    }

    .announcement-pages {
      position: absolute;
      padding-left: 5px;
      color: $font-color;
      right: 20px;
      bottom: 0;
      font-size: 14px;
    }
  }

  .modal-body {
    position: relative;

    .arrow {
      position: absolute;
      top: calc(50% - 8px);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.back {
        left: 0;
      }

      &.forward {
        right: 0;
      }
    }

    .body-text {
      max-height: 250px;
      min-height: 40px;
      width: 80%;
      margin: auto;
      overflow: auto;
      padding-right: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: $paragraph;
      font-size: 15px;

      a {
        text-decoration: none;
        color: $font-color;
        cursor: pointer;
      }

      a:hover {
        color: $yellow;
      }
    }
  }

  .modal-footer {
    position: relative;
    top: 10px;
    margin-bottom: 20px;

    .footer-content {
      position: absolute;
      right: 15px;

      .dismiss-msg {
        color: $font-color;
        margin-right: 5px;
        float: left;
        font-family: $paragraph;
        font-size: 14px;
      }

      .check-box {
        float: left;
        margin-top: 2px;
      }
    }
  }

  .divider {
    background-color: $darkgrey;
  }

  .body-text {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $lightgrey;
    }
  }

  .modal-container {
    font-family: $heading;
  }

  .MuiCheckbox-root {
    padding: 0;

    svg {
      color: $lightgrey;
    }
  }
}

.announcement-btn-container {
  .announcement-btn-clear {
    margin-top: 5px;
    margin-left: 12px;
    margin-right: 1px;
    cursor: pointer;
  }

  .announcement-btn {
    cursor: pointer;
    position: relative;
    top: 1.5px;
    margin-left: 19px;
    margin-right: 4px;
  }

  .announcement-count {
    position: absolute;
    left: 14.2px;
    top: 2.5px;
    font-size: 9px;
    font-weight: 700;

    &.double-digit {
      font-size: 7.5px;
      left: 12.5px;
      top: 3.4px;
    }
  }
}
