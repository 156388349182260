@import "../../../../styles/variables.scss";

.firewall-form {
  display: flex;
  align-items: flex-end;
  margin-bottom: 200px;

  .label-cell {
    padding: 8px 0;
    white-space: nowrap;

    &.notes {
      vertical-align: top;
    }

    .tooltip-icon {
      margin-left: 15px;
    }

    .input-tooltip {
      width: unset;
    }
  }

  .files-button {
    margin: 37px 0 0 20px;
  }

  .vendor-dropdown {
    align-items: baseline;

    .dropdown-label {
      margin-right: 120px;

      &.add {
        margin-right: 143px;
      }
    }

    .selection {
      &.flex {
        align-items: center;
      }
    }

    .vendor-options {
      height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;

      .option {
        margin-right: -40px;
        padding-right: 10px;
        overflow-x: hidden;
      }
    }
  }

  .input-box {
    height: 30px;
    width: 500px;
    margin-left: 30px;

    &.notes {
      height: 60px;
      border-radius: 1px;
      color: $black;
      font-family: $paragraph;
      margin-top: 5px;
    }
  }

  .add-btn {
    cursor: pointer;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 105px;
    height: 30px;
    font-size: 16px;
    line-height: 25px;
    margin: 35px 0 7px 20px;

    &.red-button {
      color: $red;
      border: $red 3px solid;
    }

    &:hover {
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }

  .relative-loader {
    margin: 15px;
  }

  .error-text {
    color: $red;
    margin: 0 0 8px 10px;
  }

  .error-msg {
    color: $red;
    margin-left: 20px;
  }
}

.firewall-container {
  .help-text {
    color: $font-color;
  }
}
