@import '../../variables';

.hosts-container {
  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid $border;
  }

  .hosts-tabs {
    padding-top: 50px;

    .tab-nav {
      display: flex;
      align-items: flex-end;

      .hr-line {
        background-color: $border;
        height: 1px;

        &.first {
          padding-right: 2.9em;
        }

        &.second {
          flex: 1 1 0;
        }
      }

      .tab-container {
        .tab-button {
          background-color: transparent;
          font-size: 15px;
          color: $font-color;
          letter-spacing: 1.76px;
          cursor: pointer;
          border-left: 1px solid $border;
          border-top: none;
          border-bottom: 1px solid $border;
          border-right: none;
          padding-bottom: 3px;
          font-family: $paragraph;
          padding-right: 15px;
          padding-left: 15px;

          &.active {
            border-bottom: none;
            color: $blue;
            background-image: $button-gradient;
          }

          &.border {
            border-right: 1px solid $border;
          }

          .tab-value {
            font-size: 22px;
            font-family: $heading;
          }

          &.right {
            border-right: 1px solid $border;
          }
        }
      }
    }
  }
}

.hosts-filters {
  border-top: none;
  padding-top: 1%;

  .dropdown-checkbox-title {
    color: $font-color;
  }
}