@import "../variables";

.router-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .nav-container {
    display: flex;
    width: 100%;
    color: $font-color;
    border-bottom: 1px solid $border;
    height: 50px;
    background-size: 45% 50px;
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 20px;

    a {
      text-decoration: none;
    }

    .nav-left {
      &.disabled {
        pointer-events: none;

        .nav-item {
          color: $grey;

          .enabled {
            color: $font-color;
            pointer-events: auto;
          }
        }
      }

      .nav-menu-list {
        display: flex;
        padding-left: 20px;
        align-items: baseline;
        z-index: 10;
        position: relative;

        .nav-logo {
          width: 100px;
          margin: 10px 35px 0 -10px;
        }

        .dropdown-container {
          position: absolute;
          margin-top: -1px;
        }
      }
    }

    .nav-item-container {
      cursor: pointer;
      z-index: 5;
      height: 48px;

      .nav-item {
        display: flex;
        padding: 14.4px 25px;
        cursor: pointer;
        color: $font-color;
        white-space: nowrap;
        font-size: 13px;
        letter-spacing: 1px;
        border: 1px solid transparent;
        font-family: $heading;
        font-weight: 700;

        &.disabled-nav {
          cursor: auto;
          color: $grey;
          pointer-events: none;

          &:hover {
            border: 1px solid transparent;
          }
        }

        .disabled-nav {
          cursor: auto;
          color: $grey;
          pointer-events: none;

          &:hover {
            border: 1px solid transparent;
          }
        }

        &.sub-item {
          position: relative;
          text-align: left;
          color: $font-color;
          background: linear-gradient(to right, $background 0%, $secondary-bg 35px, $secondary-bg 100%);
          border: 1px solid hsla(0, 0%, 100%, .3);
          border-left: 1px solid hsla(0, 0%, 90%, 0.8);

          &.disabled-nav {
            cursor: auto;
            color: $grey;
            pointer-events: none;

            &:hover {
              border: 1px solid transparent;
            }
          }

          &.sub-sub-item {
            color: $grey;
          }

          &:hover {
            border: 1px solid $grey;
          }

          .text {
            &.ZEROIN {
              display: flex;
              align-items: center;

              img {
                margin: -1px 0 0 5px;
              }
            }

            &.FILES {
              position: relative;
              right: 5px;
            }

            &.TICKET {
              height: 50px;
              position: relative;
              border: 1px solid rgba(255, 255, 255, 0.1);
              text-align: left;
              color: $white;
            }
          }
        }

        &.path {
          font-weight: 500;

          &:hover {
            color: $blue;
          }
        }

        &.no-select {
          cursor: default;
        }

        &.active-dropdown {
          border: 1px solid $border;
        }
      }
    }

    .nav-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: -5px;
      font-weight: 500;
      font-size: 12px;
      width: 100%;
      align-items: center;
      padding-right: 25px;

      .tenant-dropdown {
        z-index: 100;
      }

      .breach-info {
        height: 55px;
        padding: 10px 40px;

        span {
          color: $grey;
        }

        .breach-number {
          margin-bottom: 3px;
        }

        .breach-email {
          &:hover {
            color: $blue;
          }
        }
      }

      .tools-container {
        position: relative;
        min-height: 50px;
        padding-top: 5px;
        margin-left: 2px;
        margin-right: 4px;

        .gear-icon {
          width: 27px;
        }

        .tools-btn {
          z-index: 5;
          display: flex;
          padding: 10px 10px;
          cursor: pointer;
          white-space: nowrap;
          font-size: 12px;
          letter-spacing: 0.25px;
          border: 1px solid transparent;
          font-family: $paragraph;
          font-weight: 500;
          margin-top: -1px;

          a {
            color: inherit;
          }

          .text {
            position: relative;
            top: 5px;
            padding-left: 10px;
          }

          &.sub-item {
            height: 50px;
            position: relative;
            text-align: left;
            color: $font-color;
            background-color: $secondary-bg;

            &:hover {
              color: $blue;
              border: 1px solid $grey;
            }
          }

          &.path {
            background: linear-gradient(to right, $background 0%, $secondary-bg 35px, $secondary-bg 100%);
            border: 1px solid hsla(0, 0%, 100%, .3);
            border-left: 1px solid hsla(0, 0%, 90%, 0.8);

            &:hover {
              color: $blue;
            }
          }

          &:hover {
            opacity: 0.8;
          }

          &.no-select {
            cursor: default;
          }

          &.active-dropdown {
            border: 1px solid $darkgrey;
            background-color: rgba(255, 255, 255, 0.1);
          }

          &.disabled-nav {
            cursor: auto;
            color: $darkgrey;

            &:hover {
              border: 1px solid transparent;
              color: $darkgrey;
            }
          }
        }
      }

      .tools-dropdown {
        position: absolute;
        top: 55px;
        right: 0;
        // TODO uncomment when adding the Adhoc Investigations option back to tool menu
        // width: 170px;
        width: 130px;
      }

      .help-container {
        position: relative;
        min-height: 50px;
        margin-top: 6px;
        margin-left: 5px;
      }

      .help-btn {
        z-index: 5;
        display: flex;
        padding: 15px 10px;
        cursor: pointer;
        color: $font-color;
        white-space: nowrap;
        font-size: 12px;
        letter-spacing: 0.25px;
        font-family: $paragraph;
        font-weight: 500;

        a {
          color: inherit;
        }

        &.sub-item {
          position: relative;
          text-align: left;
          color: $font-color;
          background: linear-gradient(to right, $background 0%, $secondary-bg 35px, $secondary-bg 100%);
          border: 1px solid hsla(0, 0%, 100%, .3);
          border-left: 1px solid hsla(0, 0%, 90%, 0.8);

          &.disabled-nav {
            cursor: auto;
            color: $grey;
            pointer-events: none;

            &:hover {
              border: 1px solid transparent;
            }
          }

          &.sub-sub-item {
            color: $font-color;
          }

          &:hover {
            border: 1px solid $grey;
            color: $blue;
          }
        }

        &.no-select {
          cursor: default;
        }

        &.active-dropdown {
          color: $blue;
          outline: 1px solid $darkgrey;
        }
      }

      .help-dropdown {
        position: absolute;
        top: 50px;
        right: 0;
        width: 160px;
      }

      .user-menu-container {
        position: relative;
        padding: 20px 5px 0 5px;
        margin-left: 20px;
        z-index: 99;
        height: 55px;
        width: 50px;

        .user-icon {
          display: flex;
          justify-content: center;
          border: 1px solid $font-color;
          cursor: pointer;
          padding: 5px;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          color: $font-color;
          margin-top: -5px;

          &.closed {
            margin-top: -5px;
          }

          &.modal {
            cursor: unset;
          }
        }
      }
    }
  }
}
