@import "../../variables";

.MFA-settings-container {
  padding-left: 75px;
  color: $grey;
  font-family: $paragraph;
  width: 650px;
  .header {
    font-family: $heading;
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .subheader {
    font-weight: 200;
    font-size: 18px;
    padding-top: 20px;
    margin-bottom: 10px;
    clear: both;
    &.phone {
      padding-top: 10px;
    }
  }

  .description-text {
    width: 250px;
    font-weight: 200;
    font-size: 18px;
    float: left;
    margin-right: 50px;
  }

  .confirm-btn-container {
    position: relative;
    float: right;
    right: 20px;
    .confirm-btn {
      background-color: $yellow;
      padding: 10px 20px;
      height: 50px;
      outline: none;
      border: none;
      font-weight: 600;
      font-size: 15.5px;
      cursor: pointer;
      text-transform: uppercase;
      width: 230px;
      margin-top: 45px;
      &:hover {
        background-color: #9db816;
      }
      &:focus {
        outline: none;
      }
      .relative-loader {
        margin-top: -10px;
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .success-msg {
    .text {
      position: relative;
      font-size: 20px;
      margin-right: 5px;
      margin-left: 10px;
      bottom: 10px;
    }
  }
  .confirmed-setup-msg {
    position: relative;
    clear: both;
    font-size: 17px;
    top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    .checkmark {
      position: relative;
      top: 3px;
    }
  }
  .error-msg {
    position: absolute;
    bottom: 55px;
    color: $red;
    .err-icon {
      position: relative;
      top: 2px;
      color: $red;
      margin-right: 3px;
    }
  }
  .fine-print {
    position: absolute;
    width: 230px;
    font-size: 14px;
    margin-top: 3px;
  }
  .manual-qr-code {
    .manual-qr-code {
      margin-top: 10px;
      margin-left: 290px;
      background: none;
      border: none;
      font-family: $paragraph;
      font-size: 16px;
      font-weight: 500;
      color: $grey;
      cursor: pointer;
    }
    :hover {
      color: $yellow;
    }
  }
  .App-2FA-container {
    .totp-images {
      padding: 10px;
      margin-left: -50px;
      .label {
        width: 100px;
        font-size: 15px;
        text-align: center;
        margin-top: 3px;
        &.google-authenticator {
          margin-top: 0px;
        }
        &.duo {
          position: relative;
          bottom: 20px;
        }
        &.okta-verify {
          position: relative;
          bottom: 5px;
        }
      }

      .icon {
        margin-left: 15px;
        &.duo {
          top: 0px;
          position: relative;
        }
        &.okta-verify {
          left: 7px;
          bottom: 9px;
          position: relative;
        }
      }

      .google-authenticator {
        padding: 10px;
      }
      .microsoft-authenticator {
        padding: 10px;
      }
      .duo {
        padding: 10px;
      }
    }

    .the-code{
        position: relative;
        left: 80px;
    }

    .verification-code-container {
      margin-top: 15px;
      .input-label {
        font-weight: 600;
        font-family: $heading;
        font-size: 15px;
        margin-bottom: 5px;
        color: $lightgrey;
      }
      padding-right: 45px;
      input {
        width: 100%;
        height: 55px;
        margin-top: 8px;
        border-radius: 5px;
        background-color: #191a1a;
        color: $white;
        border: 1px solid $white;
        padding-left: 18px;
        font-size: 15px;
        &:focus {
          border: 1px solid $yellow;
        }
      }
    }

    .qr-error {
      color: $red;
    }
    .manual-qr-code {
      .reveal-btn{
        position: relative;
        left: 94px;
        bottom: 10px;
        font-size: 15px;
      }
      .key {
        margin-left: -290px;
        font-size: 14px;
        word-break: break-word;
      }
      .account-name {
        margin-left: -290px;
        font-size: 14px;
        word-break: break-word;
      }
    }
  }
}
