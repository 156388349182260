@import '../../variables';

.mdr-license-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;

  .az-wg-logo {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 125px;
  }

  .managed-services-icon {
    width: 100px;
    margin: 0 30px -10px 0;
  }

  .mdr-title {
    padding: 20px 0;
  }
}
