@import "../../../variables";

.cloud-table-row {
  .feedback-icon {
    position: absolute;
  }

  .fail-icon {
    color: $red;
    position: relative;
    top: 5px;
    right: 170px;
  }

  .success-icon {
    position: relative;
    top: 3px;
    right: 170px;
  }

  .row-name {
    float: left;
    font-size: 17px;
    position: relative;
    top: 3px;
  }

  .table-icon-container {
    float: right;
    position: relative;
    right: 10px;

    .loader-container {
      position: absolute;
    }

    .relative-loader {
      position: relative;
      left: -121px;
      bottom: 12px;
    }
  }

  .table-icon:hover {
    opacity: 0.8;
  }

  .delete-tooltip {
    float: left;
    width: 60px;
  }

  .edit-tooltip {
    float: left;
    width: 43px;
  }
  .test-error {
    position: relative;
    color: $red;
    right: 165px;
    font-size: 15px;

    .err-icon {
      position: relative;
      top: 3px;
      margin-right: 0;
      color: $red;
    }
  }
}
