@import "../../variables";

.knowledge-graph-container {
  background: $button-gradient;
  min-height: 95vh;
  display: flex;
  color: $font-color;

  .fdg-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    .fdg {
      flex-grow: 1;
      max-width: 100%;
      width: auto;
    }

    .zoom-container {
      position: absolute;
      bottom: 20px;
      right: 15px;
      display: flex;
      justify-content: flex-end;
      user-select: none;
    }
  }

  .switch-container {
    position: absolute;
    bottom: 25px;
    right: 85px;
    display: flex;
    justify-content: flex-end;
    user-select: none;
  }

  .robot {
    &.error-img {
      width: 300px;
      margin: 27vh 40%;
    }
  }

  .loader-container {
    min-height: 650px;
    height: 95vh;
  }

  .loader-custom {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    &.relative {
      position: relative;
    }
  }

  .dim-button {
    z-index: 99;
    font-family: $paragraph;
    font-size: 12px;
    border: 1.25px solid $border;
    color: #808e8e;
    padding: 1px 5px 1px 5px;
    cursor: pointer;
    position: relative;
    cursor: pointer;
    z-index: 12;
    float: left;
    border-radius: 10%;

    &:hover {
      opacity: 0.8;
    }
  }
}
