@import "../../../variables";

.child-tenants-dropdown {
  &.virtual-machine {
    left: 300px;
  }
}

.virtual-machine-container {
  .onboarding-title {
    &.contacts {
      float: revert;
    }
  }

  .vm-description {
    display: flex;
    width: 100%;
    min-width: 800px;
    margin-bottom: 40px;
    justify-content: space-between;

    span {
      margin-right: 30px;
    }

    .activation-code {
      white-space: nowrap;
    }

    .link {
      color: $white;
      cursor: pointer;
      text-decoration: none;

      &.instructions {
        font-size: 14px;
        white-space: nowrap;
      }

      &.download-script {
        float: left;
      }

      &:hover {
        color: $blue;
      }
    }
  }
}
