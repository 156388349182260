@import '../../variables';
@import './MaturityAssessmentVariables';

.maturity-assessment-page-container {
  display: flex;
  flex-direction: column;

  .maturity-assessment {
    &.expanded {
      .table-row {
        &.expanded {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.1);
        }

        .td-value {
          &.control {
            min-width: $expanded-control;
            width: $expanded-control;
          }

          &.category {
            min-width: $expanded-category;
            width: $expanded-category;
          }

          &.tool {
            min-width: $expanded-tool;
            width: $expanded-tool;
          }

          &.name {
            min-width: $expanded-name;
            width: $expanded-name;
          }

          &.difficulty {
            min-width: $expanded-difficulty;
            width: $expanded-difficulty;
          }

          &.status {
            min-width: $expanded-status;
            width: $expanded-status;
          }
        }
      }
    }
  }

  .maturity-assessment {
    .table-row {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .td-value {
        margin-right: 20px;

        &.icon {
          min-width: 70px;
          width: 70px;
          margin-left: -15px;
          margin-right: unset;
        }

        &.control {
          min-width: $control;
          width: $control;

          .icon {
            filter: brightness(0) saturate(100%) invert(47%) sepia(79%) saturate(291%) hue-rotate(176deg) brightness(92%) contrast(101%);
            width: 60px;
            margin-right: 20px;

            &.CMMC {
              filter: unset;
            }
          }
        }

        &.category {
          min-width: $category;
          width: $category;
          padding-left: 1px;
        }

        &.tool {
          min-width: $tool;
          width: $tool;
        }

        &.name {
          min-width: $name;
          width: $name;

          .name-text {
            width: 90%;
          }
        }

        &.difficulty {
          min-width: $difficulty;
          width: $difficulty;
          margin-top: 15px;
        }

        &.status {
          width: $status;
          margin-top: 15px;

          .status-bar-container {
            width: 100%;
          }
        }

        &.limit-text-lines {
          margin-top: auto;
          margin-bottom: auto;
          height: 50px;
          white-space: break-spaces;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow-y: hidden;
          line-clamp: 2;
          overflow-x: hidden;
        }
      }
    }
  }
}
