@import '../../variables';

.table-header-wrapper {
  box-shadow: inset 0 0.5px 0 rgb(46 82 89), inset 0 -0.5px 0 $grey;
  color: $font-color;
  height: 40px;
  background-color: $table-header-bg;
  font-family: $paragraph;
  text-align: left;
  position: sticky;
  top: 0;

  .header-item {
    font-weight: 300;
    padding-left: 30px;
  }
}
