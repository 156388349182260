@import "../../variables";

.expanded-details-container {
  position: absolute;
  z-index: 99;
  background-color: $secondary-bg;
  font-size: 16px;
  padding: 20px;
  color: $font-color;
  max-width: 700px;
  max-height: 302px;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  border: 1px solid $border;
  font-family: $paragraph;

  &.hover{
    min-width: 270px;
    display: none;
    overflow: hidden;
    pointer-events: none;
  }

  .table-title {
    letter-spacing: 2px;
    margin-bottom: 15px;
    font-family: $heading;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }

  .expanded-details {
    .detail {
      .label {
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
        color: $grey;
        vertical-align: top;
        text-transform: capitalize;
      }

      .value {
        font-weight: 200;
        padding-left: 20px;
        text-align: left;
      }
    }
  }

  .view-button-container {
    cursor: pointer;

    td {
      padding-top: 20px;
      font-family: $paragraph;

      &:hover {
        color: $blue;
      }

      &:last-child {
        text-align: right;
      }

      .view-btn {
        border: 1px solid $border;
        width: fit-content;
        padding: 8px;
        white-space: nowrap;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
