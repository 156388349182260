@import "../../variables";

.top-countries-container {
  clear: left;
  margin-top: 5vh;

  .country-row {
    color: $font-color;

    &:hover {
      color: $blue;
    }
  }

  td {
    padding-right: 50px;
  }

  .countries-title {
    letter-spacing: 1.5px;
    font-family: $heading;
    margin-bottom: 5px;
  }

  .country-headers {
    color: $grey;
    font-family: $paragraph;
    font-size: 14px;
    cursor: pointer;
    min-width: 130px;
    user-select: none;

    img {
      position: relative;
      margin-right: 3px;
      margin-bottom: 5px;
    }
  }

  .countries-amount {
    text-align: right;

    &.name {
      text-align: left;
      width: 12vw;
    }
  }

  .no-data {
    color: $font-color;
    font-size: 18px;
  }
}
