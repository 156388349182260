@import "../../variables";

.loader-btn-container {
  .loader-btn {
    font-family: $paragraph;
    cursor: pointer;
    color: $font-color;
    border: 2px solid $border;
    text-align: center;
    width: 105px;
    height: 30px;
    font-size: 16px;
    line-height: 25px;
    user-select: none;

    .relative-loader {
      width: 50px;
    }

    &:hover {
      color: $blue;
    }
  }

  .relative-loader {
    margin-left: 27px;
  }
}
