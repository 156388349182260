@import "../variables";

.user-menu {
  display: flex;
  z-index: 1;
  margin: -5px -20px 0 0;
  position: absolute;
  top: 55px;
  width: 250px;
  border: 1px solid $border;
  font-family: $paragraph;
  background-color: $secondary-bg;
  color: $font-color;
  padding: 10px 0;

  &.modal {
    align-items: center;

    .menu-btns {
      display: flex;
      flex-direction: column;

      .user-menu-btn {
        width: 119px;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.25px;
        font-weight: 500;
        margin: 0 0 0 8px;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  .email {
    padding: 5px 10px 8px 10px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
  }
}