@import '../../variables';

.multitenant-dashboard-container {
  display: flex;
  background-color: $background;
  max-height: 100vh;

  &.watchguard {
    display: block;
    text-align: center;
  }

  .coming-soon-text {
    font-size: 30px;
    margin: auto;
    padding-top: 18%;

    &.sub {
      font-size: 20px;
      padding-top: 15px;
    }
  }

  .left-dashboard {
    background: $button-gradient;
    min-width: 840px;
    height: 100vh;
    overscroll-behavior-y: contain;
    overflow-y: scroll;
    border-left: 1px solid $border;
    overscroll-behavior-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .key-metrics-container {
    position: unset;
  }

  .right-dashboard {
    padding: 40px 40px 100px 40px;
    width: 50%;
    left: 50%;
    overflow-y: scroll;
    border-left: 1px solid $border;
    overscroll-behavior-y: auto;
    height: 100vh;
    background: $button-gradient;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .multi-tenant-panel {
      width: 100%;

      .legend-container {
        white-space: nowrap;
      }

      &.detections {
        padding-top: 20px;
      }

      &.vulnerabilities {
        padding-top: 10px;
      }

      .panel-tables {
        width: fit-content;
        max-height: 215px;
        overflow-y: scroll;
        overscroll-behavior: contain;

        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }

        .label {
          cursor: pointer;

          &.inactive {
            pointer-events: none;
          }

          &:hover {
            color: $blue;
          }
        }
      }
    }

    .panel-divider {
      background-color: $border;
      height: 1px;
      border-width: 0;
      margin: 10px -40px 20px -40px;
    }

    .multi-tenant-graph-icon {
      width: 85px;
      position: relative;
      top: 135px;
      opacity: 0.7;
      right: -10px;
      pointer-events: none;
    }

    .graph-container {
      font-size: 14px;
      height: 250px;
      width: 100%;

      .title-and-legend {
        margin-bottom: -75px;
      }

      .chart-container {
        justify-content: space-between;
      }
    }
  }
}
