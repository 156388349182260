@import '../../variables';

.table-header-container {
  display: flex;
  padding: 0 50px;
  font-family: $paragraph;
  font-size: 16px;
  margin-bottom: -1px;
  border-top: 1px solid #616B6B;
  border-bottom: 1px solid #616B6B;
  color: $font-color;
  height: 40px;
  background-color: $table-header-bg;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  cursor: default;
  min-width: 100%;
  z-index: 10;

  .td-value {
    width: 25%;
    display: flex;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.6);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
    overflow: visible;

    &.Case {
      width: 25%;
    }

    &.Source {
      width: 15%;
    }

    &.Family {
      width: 15%;
    }

    &.Time {
      margin-left: -4px;
      width: 20%;
    }

    &.Severity {
      width: 20%;
    }

    &.Type {
      margin-right: 20px;
    }

    &.Status {
      width: 15%;
    }

    &.Action {
      width: 13%;

      .tooltip-container {
        .title-tooltip {
          white-space: break-spaces;
          width: 200px;
          margin-left: -20%;
        }
      }
    }

    .sort {
      font-size: 16px;
      background: none !important;
      border: none;
      padding: 0 !important;
      font-weight: 300;
      color: $font-color;
      font-family: $paragraph;
      cursor: pointer;
    }

    .unsorted {
      display: grid;
      width: 15px;
      margin-left: 5px;
    }

    .desc {
      margin-left: 5px;
      width: 15px;

      &.Action {
        margin-left: 63px;
      }
    }

    .asc {
      margin-left: 5px;
      width: 15px;

      &.Action {
        margin-left: 63px;
      }
    }
  }

  .status-bar {
    width: 25%;
  }
}
