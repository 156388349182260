@import "../../variables";

.map {
  overflow: hidden;

  &.incoming {
    border-bottom: 1px solid $border;
    margin-bottom: 15px;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  path {
    fill: $secondary-bg;
    stroke: $font-color;
    stroke-width: 1px;
    opacity: 0.9;
  }

  .projection-container {
    position: relative;
    top: -110px;
    width: 100%;
    min-width: 1000px;
    max-height: 40vh;
    z-index: 9;
  }

  .map-title {
    font-family: $heading;
    position: relative;
    top: 20px;
    z-index: 10;
    margin-left: 50px;
    width: 170px;
    letter-spacing: 1.5px;
    white-space: nowrap;
  }

  .country-circle {
    fill: $secondary-bg;
    opacity: 0.85;
    border: 1px $border;
    stroke: $font-color;
    stroke-width: 0.5px;

    &:hover {
      opacity: 1;
    }
  }

  .country-marker {
    text {
      fill: $font-color;
    }
  }

  &.outgoing {
    path {
      fill: $secondary-bg;
      stroke: $font-color;
    }

    .country-circle {
      fill: $secondary-bg;
      opacity: 0.85;
      border: 1px $border;
      stroke: $font-color;
      stroke-width: 0.5px;
    }
  }

  .country-tooltip-container {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: $secondary-bg;
    padding: 5px 10px;
    color: $font-color;
    border: 1px solid $border;
    font-size: 16px;
    z-index: 11;

    .country-name {
      float: left;
      margin-right: 20px;
    }

    .tooltip-right {
      float: left;
    }

    .country-allowed {
      color: $grey;
      font-size: 14px;
    }
  }
}
