@import '../../../variables';

.trend-graph {
  font-family: $paragraph;

  .line {
    stroke-width: 2;
    fill: none;
  }

  .axis path {
    stroke: transparent;
  }

  .axis{
    stroke: $font-color;
  }

  .text {
    font-size: 12px;
  }

  .title-text {
    font-size: 15px;
  }

  .tick line{
    visibility:hidden;
  }
  .tick text{
    font-family: $paragraph;
  }

  .area {
    fill: url(#gradientFill);
  }
}
