@import '../../variables';
@import './EndpointsVariables';

.endpoints-container {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .table-header-container {
    .td-value {
      margin-right: 20px;

      &.last_seen {
        min-width: $time;
        width: $time;
      }

      &.EDR {
        width: $edr;
      }

      &.hostname {
        min-width: $hostname;
        width: $hostname;
      }

      &.status {
        min-width: $status;
        width: $status;
      }

      &.os_platform_name {
        min-width: $os_type;
        width: $os_type;
      }

      &.os_version {
        width: $os_version;
      }

      &.Firewall {
        width: $firewall;
      }

      &.Encryption {
        width: $encryption;
      }

      &.Patches {
        width: $patches;
      }

      &.Profiler {
        width: $profiler;
      }
    }
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 58px;
    font-size: 18px;
    color: #C0D0D0;
  }

  .gray-scale {
    opacity: 0.72;
    font-size: 16.16px;
    color: #788686;
    letter-spacing: -0.03px;
  }

  .table-row {

    .td-value {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 20px;

      .expand-btn {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        margin-left: -25px;
        margin-right: 8px;

        &.minus {
          font-size: 30px;
          margin-top: 0;
          margin-right: 8px;
        }
      }

      &.last_seen {
        min-width: $time;
        width: $time;
      }

      &.hostname {
        min-width: $hostname;
        width: $hostname;
      }

      &.status {
        min-width: $status;
        width: $status;
        padding-left: 4px;
        margin-right: 20px;
      }

      &.EDR {
        width: $edr;
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin-top: 20px;
      }

      &.Profiler {
        width: $profiler;
      }

      &.os_platform_name {
        width: $os_type;
        min-width: $os_type;

        .threat-icon {
          width: 3.5em;
          height: 52px;
          margin-top: 5px;
          margin-left: -5px;
        }
      }

      &.os_version {
        width: $os_version;
      }
    }

    .left-row {
      display: flex;
      margin-right: 20px;
      width: $os_type;

      .threat-icon {
        width: 3.5em;
        height: 52px;
        margin-top: 5px;
      }

      &.expanded {
        margin-right: 9.5em;
        margin-left: 5em;
      }

      .td-top {
        font-weight: 350;
        font-size: 16.16px;
        color: #C0D0D0;
        letter-spacing: -0.03px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 20px 0 -5px 10px;
        overflow-x: hidden;

        &.expanded {
          color: rgba(255, 255, 255, 0.6);

          &.icon {
            padding-left: 3.4em;
          }
        }

        &.true {
          white-space: pre-line;
          overflow-wrap: break-word;
        }
      }

      .td-bottom {
        font-size: 16.16px;
        color: #8CA3A3;
        letter-spacing: -0.05px;
        margin: 5px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.expanded {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .firewall-container {
      display: flex;
      width: $firewall;
      margin-top: 10px;
      margin-right: 20px;

      .icon-container {
        .icon-boolean {
          padding-left: 5px;
          font-size: 16.16px;
          color: #859898;
          letter-spacing: -0.03px;
        }

        .firewall-container {
          display: inline-flex;
        }
      }
    }

    .encryption-container {
      margin-top: 10px;
      margin-right: 20px;
      display: flex;
      width: $encryption;

      .icon-container {
        .icon-boolean {
          padding-left: 5px;
          font-size: 16.16px;
          color: #859898;
          letter-spacing: -0.03px;
        }

        .encryption-container {
          display: inline-flex;
        }
      }
    }

    .right-row {
      display: flex;
      width: $patches;
      margin-top: 20px;
      margin-right: 20px;
      cursor: auto;

      &.updates {
        padding-left: 4px;
      }

      .table-cell {
        display: flex;
        flex-direction: column;
        align-items: center;

        .highlight-text {
          color: white;
          cursor: pointer;
        }

        .highlight-text:hover {
          color: $yellow;
        }
      }

      .td-top {
        font-weight: 350;
        color: rgba(255, 255, 255, 0.6);
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;

        &.left {
          padding-left: 8em !important;
        }

        .highlight-text {
          color: #C0D0D0;
        }
      }

      .td-bottom {
        opacity: 0.61;
        font-size: 16.16px;
        color: #C0D0D0;
        letter-spacing: -0.03px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        overflow-x: hidden;

        &.true {
          white-space: pre-line;
          overflow-wrap: break-word;
          padding-bottom: 5px;
        }

        &.expanded {
          opacity: 1;
        }
      }
    }
  }

  .endpoint-pagination {
    margin-top: -20px;
  }
}