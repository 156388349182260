@import "../../variables";

.feedback-buttons {
  .action-button {
    margin-left: 15px;
    float: left;
    cursor: pointer;
    .MuiSvgIcon-root {
      color: $lightgrey;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
