@import '../../../variables';

.assets-and-details {
  .custom-checkbox {
    &:not(.Mui-checked) {
      .MuiSvgIcon-root {
        fill: $font-color;
      }
    }
  }

  .dropdown-container {
    font-family: $paragraph;

    &.global-action-dropdown {
      &.open {
        width: unset;
        position: relative;
        background-color: $secondary-bg;
        border: 1px solid $border;
        padding: 5px 10px 10px;
        white-space: nowrap;
        border-radius: 2px;
        z-index: 25;
      }
    }

    &.dropdown-sort {
      margin: -5px 0 0;
      white-space: nowrap;
      width: 120px;

      .custom-checkbox {
        margin-right: 5px;
      }

      .page-loading {
        &.relative-loader {
          position: relative;
        }
      }
    }

    &.action-dropdown {
      width: 90px;

      &.open {
        width: 112px;
        position: relative;
        background-color: $secondary-bg;
        border: 1px solid $border;
        padding: 5px 10px 10px;
        border-radius: 2px;
        z-index: 100;
      }
    }
  }

  .vulnerabilities-tab-table-container {
    &.vulnerabilities {
      margin-bottom: 200px;

      .table-body {
        .table-row {
          cursor: pointer;
          border-bottom: 1px solid $darkgrey;
          padding: 0;
          height: 30px;
          background-color: transparent;

          &:hover {
            background-color: transparent;
            color: $blue;
          }

          &.headers {
            height: 23px;
            font-size: 13px;
            border-top: none;
            margin-top: -25px;
            font-family: $heading;
            color: $font-color;

            .caret {
              margin: 0 0 1px 5px;
            }

            .action_taken {
              margin-left: auto;
              width: 305px;

              .custom-checkbox {
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }

          td {
            vertical-align: middle;

            &.Action {
              width: 205px;
              margin-left: auto;
            }

            &.comment {
              width: 82px;

              .icon {
                width: 20px;
                height: 20px;
                margin-top: 3px;
                cursor: pointer;

                &.add {
                  fill: $font-color;

                  &:hover {
                    fill: $blue;
                  }
                }

                &.edit {
                  stroke: $font-color;

                  &:hover {
                    stroke: $blue;
                  }
                }
              }
            }
          }

          .hostname {
            width: 250px;
          }

          .ipaddress {
            width: 250px;
          }

          .open_issues {
            width: 200px;
          }
        }
      }
    }
  }
}
