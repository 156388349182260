@import '../../variables.scss';

.export-dropdown {
  position: relative;
  width: 150px;
  bottom: -53px;
  color: $font-color;

  .export-container {

    .export-button {
      background-color: transparent;
      color: $font-color;
      padding: 5px 5px;
      cursor: pointer;
      margin-top: 3px;
    }

    .export-toggle {
      cursor: pointer;
      background-color: transparent;
      white-space: nowrap;
      color: $font-color;
      border: none;
      font-family: $paragraph;
      font-size: 16px;
      line-height: 1.47;
    }

    .export-toggle-container {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 5px;

      .svg {
        transform: scale(1.8);
      }
    }

    .arrow-down-export {
      height: 23px;
      width: 20px;
      color: $font-color;
      margin-top: 2px;
    }

    .arrow-up-export {
      height: 23px;
      width: 20px;
      color: $font-color;
      margin-top: 3px;
    }

    .export-menu {
      background-color: $secondary-bg;
      color: $grey;
      padding: 15px;
      border: 1px solid $border;
      position: absolute;
      top: 40.5px;
      border-radius: 10px !important;
      overflow: auto;
      white-space: nowrap;
      max-width: 344px;
      z-index: 10;

      .export-menu-item {
        color: $font-color;
        cursor: pointer;

        &.true {
          color: $grey;
        }
      }

      .export-menu-divider {
        border-bottom: 1.5px solid $border;
        margin: 8px 0;
      }

      &.false {
        display: none;
      }

      &.past-files {
        &.true {
          left: -200px;
        }
      }

      a {
        color: $font-color;
      }
    }
  }
}
