@import '../../variables';

.data-list-container-hygiene {
  position: relative;
  margin-top: 0;
  color: $font-color;
  text-align: right;
  flex: 1 1;
  display: flex;
  justify-content: center;
  line-height: 1.8;
  font-weight: 200;
  padding: 1.5em 0 8.4em 33px;

  &.true {
    background-image: $button-gradient;
  }

  .table-title {
    color: white;
    white-space: nowrap;
    font-weight: 450;
    letter-spacing: 1.5px;
    font-family: $heading;

    &.true {
      background-image: linear-gradient($font-color, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: fit-content;
    }
  }

  .table-container {
    border-spacing: 0;
    margin-top: 1.8em;
    white-space: nowrap;
    position: relative;
    right: 18%;
    max-width: fit-content;

    .data-table-row {
      line-height: 35px;

      .data-table-cell {
        &.true {
          background-image: linear-gradient($font-color, transparent);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .amount {
    font-size: 25px;
    padding-left: 10px;
    font-weight: 100;
    text-align: left;
    color: $font-color;

    &.true {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.status {
      color: $font-color;
    }

    .arrow {
      margin: 0 0 5px 5px;
      height: 18px;
    }

    &.yellow {
      color: $yellow;

      &.true {
        background-image: linear-gradient($yellow, transparent);
      }
    }

    &.green {
      color: $green;

      &.true {
        background-image: linear-gradient($green, transparent);
      }
    }
  }

  .failed {
    color: $orange;
  }

  .warning {
    color: $yellow;
  }

  .medium {
    color: $yellow;
  }

  .passed {
    color: $green;
  }

  .informational {
    color: $low-font;
  }
}