@import "../../variables";

.llm-container {
  font-weight: bold;
  border-bottom: 1px solid $border;
  padding: 0 0 15px 30px;
  display: flex;
  margin: -18px 0 0 -20px;

  .MuiIconButton-root {
    color: rgb(121, 133, 133);

    &:hover {
      color: $blue;
    }
  }

  .llm-text-field {
    min-width: 250px;
    background-color: transparent;
    border: 1px solid $border;
    color: $font-color;
    margin-top: -10px;
    font-family: $paragraph;
    padding: 8px 5px 8px 10px;
    height: 86px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $grey;
    }
  }
}
