@import '../../variables';

.cloud-card-row {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border;

  .coming-soon-text {
    position: relative;
    left: 8%;
    top: 85px;
    width: 0;
  }

  .cloud-card-container {
    border-right: 1px solid $border;
    display: flex;
    justify-content: center;
    font-family: $paragraph;

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $secondary-bg;
      }
    }

      padding: 30px 50px 55px;
      flex-grow: 1;

    .cloud-type {
      margin-left: 10%;
      margin-right: 40px;

      &.o365 {
        margin-left: 0;
      }
    }

    .card-data {
      flex-grow: 1;

      &.salesforce {
        margin-right: -70px;
      }
    }

    .connected {
      display: flex;
      margin-bottom: 0;

      .connected-text {
        margin-left: 10px;

        &.red {
          color: $red;
          font-family: $paragraph;
          letter-spacing: -0.5px;
          transform: scale(0.9, 1);
          margin-left: 5px;
        }
      }
    }

    .circle {
      display: flex;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-left: 15px;
      margin-top: 6px;
      background-color: $secondary-bg;

      &.green {
        background-color: $green;
      }

      &.red {
        background-color: $red;
      }
    }

    .accounts {
      color: $font-color;
      margin-left: 35px;
      height: 19px;
      cursor: pointer;
      width: fit-content;

      &:hover {
        color: $blue;
      }
    }

    .display-container {
      margin-bottom: -35px;
      margin-top: 15px;
      height: 80px;

      .display-values {
        display: flex;
        font-size: 15px;
        margin-bottom: -15px;

        .name {
          text-align: right;
          width: 98px;
          color: $grey;
          margin-right: 10px;
          height: 22px;
        }

        .value {
          text-align: left;
          color: $font-color;
          white-space: nowrap;

          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .cloud-card-row {
    min-width: 1680px;

    .cloud-card-container {
      min-width: 390px;

      &.salesforce {
        min-width: 420px;
        padding: 30px 70px 30px 30px;
      }
    }
  }
}
